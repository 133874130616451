<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12  d-flex flex-column mb-q">
      <TablePaginationV3ForPortalSessions
        v-if="showTablePaginationV3Component"
        @emitFilterSettings="emitFilterSettingsHandler"
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'profile', 'location', 'social', 'sort', 'limit']"
        :showSlotForProfileSelctor="true"
        :options="tablePaginationOptions"
        :q="filters.q"
        :filterSettings="filterSettings"
      ></TablePaginationV3ForPortalSessions>
    </div>
    <div class="col-lg-4">
      <div class="filters__multiple">
        <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
          <WButton secondary outline sm
            v-if="isFiltersActive"
            @click.stop.prevent="resetAllFilters"
          >
            <i class="fa fa-close"></i>
            {{ $t('general.resetAllFilters') }}
          </WButton>
        </div>
      </div>
    </div>

    <!--    <div class="col-lg-12">-->
    <!--      <div class="card">-->
    <!--        <div class="d-flex flex-column justify-content-start align-items-start p-1">-->
    <!--          <div>-->
    <!--            <div><small>{{$t('portalSessions.tabeleViewTypeCaption')}}</small></div>-->
    <!--          </div>-->
    <!--&lt;!&ndash;          <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  <span v-for="(value, key) in typesOfTableViews">&ndash;&gt;-->
    <!--&lt;!&ndash;          key: {{key}}, value: {{value}}&ndash;&gt;-->
    <!--&lt;!&ndash;        </span>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--          <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">-->
    <!--            <label v-for="(value, key) in typesOfTableViews" class="btn btn btn-outline-success"-->
    <!--                   :class="{ active: tableViewType === key }">-->
    <!--              <input-->
    <!--                type="radio"-->
    <!--                name="typeFilter"-->
    <!--                @click="changeTableViewType(key)"-->
    <!--              />-->
    <!--              {{ value }}-->
    <!--            </label>-->

    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->

    <div class="col-lg-12">
      <div class="card">
        <div v-if="isSessionsLoading || isPortalSessionUpdateRequestExecuting || isCpeLoading || isWlansLoading">
          <Loader-spinner />
        </div>
        <div class="card-header" id="viewsChangeButtons">
          <div class="d-flex flex-column justify-content-start align-items-start">
            <div class="mb-h">
              <div>
                <small>{{ $t('portalSessions.tabeleViewTypeCaption') }}</small>
              </div>
            </div>
            <!--          <div>-->
            <!--                  <span v-for="(value, key) in typesOfTableViews">-->
            <!--          key: {{key}}, value: {{value}}-->
            <!--        </span>-->
            <!--          </div>-->
            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
              <label
                v-for="key in typesOfTableViews"
                class="btn btn btn-outline-info"
                :class="{ active: tableViewType === key }"
              >
                <input type="radio" name="typeFilter" @click="changeTableViewType(key)" />
                {{ $t(`portalSessions.tableViewType${key}`) }}
              </label>
            </div>
          </div>
          <div
            v-if="!buttonsAreVisible"
            class="d-flex flex-column justify-content-start align-items-start card-header visability-buttons-when-not-visible"
          >
            <!--          <div>-->
            <!--                  <span v-for="(value, key) in typesOfTableViews">-->
            <!--          key: {{key}}, value: {{value}}-->
            <!--        </span>-->
            <!--          </div>-->
            <div class="btn-group btn-group-sm float-left" data-toggle="button">
              <label
                v-for="key in typesOfTableViews"
                class="btn btn btn-outline-info"
                :class="{ active: tableViewType === key }"
              >
                <input type="radio" name="typeFilter" @click="changeTableViewType(key)" />
                {{ $t(`portalSessions.tableViewType${key}`) }}
              </label>
            </div>
          </div>
        </div>
        <div class="card-header d-flex align-items-center justify-content-between">
          <div>
            <i class="fa fa-users mr-0"></i>
            {{ $t('portalSessions.title') }}
            <span class="text-muted ml-3" v-if="portalSessionsList.length">
              {{ this.portalSessionsList.length }} {{ this.$t('portal.paginationOf') }}
              {{ this.totalResultPortalSessions }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <download-extract-v2
              newXlsAutoColumnsWidth
              v-if="!isSessionsLoading && portalSessionsList && portalSessionsList.length > 0"
              class="ml-h"
              :forceEnableA2PdfPageSize="tableViewType === 'standardView'"
              :data="formatPortalSessions()"
              :data-for-excel="formatPortalSessionsToExcel()"
              :excel-file-name="getFileNameForDownloadFiles(`${$t('portalSessions.title')}-${$t(
                `portalSessions.tableViewType${tableViewType}`
              )}`)"
              :filename="getFileNameForDownloadFiles(`${$t('portalSessions.title')}-${$t(
                `portalSessions.tableViewType${tableViewType}`
              )}`)"
              :downloads-formats-for-show="{
                oldCsv: false,
                newCsv: true,
                oldXls: false,
                newXls: true,
                pdf: true
              }"
            />
          </div>
        </div>
        <div class="card-block card-block__table table-responsive">
          <transition name="tablesessionstransition">
            <table class="table table-hover" v-if="tableViewType === 'standardView'">
              <thead>
                <tr class="sessions-table-cursor-default">
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.identityTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.stateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">{{ $t('general.type') }}</th>
                  <!--                <th scope="col" class="text-nowrap align-middle text-center">-->
                  <!--                  {{ $t('portalSessions.sessionCreationDate') }}-->
                  <!--                </th>-->
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.sessionCreationExpirationDate') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.mac') }}</th>
                  <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.ip') }}</th>
                  <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.os') }}</th>
                  <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.osVersion') }}</th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.browser') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.profile') }}</th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.cpeTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.wlanTableCaption') }}
                  </th>
                  <!--                <th scope="col" class="align-middle text-center">{{$t('portalSessions.costForTable')}} <info  :content="$t('portalSessions.costForTableInfo')" /></th>-->
                  <th scope="col" class="text-nowrap align-middle text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :id="session.id"
                  @click="selectSession(session)"
                  class="sessions-table-cursor-default"
                  v-for="session in portalSessionsList"
                  :key="session.id"
                  :class="{
                    'session-deleted': isSessionDeleted(session),
                    'table-active': session.id === selectedSessionId
                  }"
                >
                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionIdentity(session) }">
                    <span v-if="getSessionIdentity(session) !== ''">
                      {{ getSessionIdentity(session) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionStateTypeTranslateForTable(session.state) }"
                  >
                    <span v-if="session.state">
                      {{ getSessionStateTypeTranslateForTable(session.state) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <i
                      v-if="session.ua.type"
                      class="fa"
                      v-tooltip="{ content: $t(`portalSessions.ua.${session.ua.type}`) }"
                      :class="{
                        'fa-desktop': session.ua.type === 'desktop',
                        'fa-mobile': session.ua.type === 'mobile',
                        'fa-tablet': session.ua.type === 'tablet',
                        'fa-wrench': session.ua.type === 'bot'
                      }"
                    ></i>
                  </td>
                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.create">-->
                  <!--                    {{`${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td class="align-middle text-center">
                    <div class="d-flex flex-column">
                      <div>
                        <small v-if="session.create">
                          {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                      <div>
                        <small v-if="session.expire_at">
                          {{
                            `${moment(session.expire_at * 1000).format('ll')} ${moment(session.expire_at * 1000).format(
                              'LTS'
                            )}`
                          }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                    </div>
                  </td>
                  <td
                    class="align-middle text-center max-width-with-hide-in-sessions-table"
                    v-tooltip.right="{ content: session.mac }"
                  >
                    <span v-if="session.mac">
                      {{ session.mac }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <span v-if="session.ip">
                      {{ session.ip }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <span v-if="session.ua.os">
                      {{ session.ua.os }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <span v-if="session.ua.os_version">
                      {{ session.ua.os_version }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center max-width-with-hide-in-sessions-table">
                    <span v-if="session.ua.name">
                      {{ session.ua.name }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <span v-if="session.profile">
                      {{ getProfileNameByID(session.profile) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center">
                    <span v-if="getCpeName(getCpeIdFromSession(session))">
                      {{ getCpeName(getCpeIdFromSession(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <span v-if="getWlanName(getWlanIdFromSession(session))">
                      {{ getWlanName(getWlanIdFromSession(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.hasOwnProperty('cost') && isSessionHasSMSAuthNType(session)">-->
                  <!--                    {{session.cost}} {{$t('general.rub')}}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td>
                    <i
                      :title="$t('portalSessions.deleteSessionButtonCaption')"
                      v-if="!isSessionDeleted(session)"
                      @click="openDeleteSessionModalWindow(session)"
                      class="fa fa-remove fa-lg mr-q text-danger user-action sessions-table-cursor-pointer"
                    ></i>
                    <i
                      :title="$t('portalSessions.undoDeleteSessionButtonCaption')"
                      v-else
                      @click="openUndoDeleteSessionModalWindow(session)"
                      class="fa fa fa-key fa-lg mr-q text-success user-action sessions-table-cursor-pointer"
                    ></i>
                  </td>
                </tr>
                <tr v-if="!portalSessionsList.length">
                  <td colspan="14" class="no-data-row">
                    <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </transition>
          <transition name="tablesessionstransition">
            <table class="table table-hover" v-if="tableViewType === 'authenticationView'">
              <thead>
                <tr class="sessions-table-cursor-default">
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.identityTableCaption') }}
                  </th>
                  <!--                <th scope="col" class="text-nowrap align-middle text-center">-->
                  <!--                  {{ $t('portalSessions.sessionCreationDate') }}-->
                  <!--                </th>-->
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.sessionCreationExpirationDate') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.stateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.authenStateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.authenTypeTableCaption') }}
                  </th>
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.costForTable') }}
                    <info :content="$t('portalSessions.costForTableInfo')" />
                  </th>

                  <!--                <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.profile') }}</th>-->
                  <th scope="col" class="text-nowrap align-middle text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :id="session.id"
                  @click="selectSession(session)"
                  class="sessions-table-cursor-default"
                  v-for="session in portalSessionsList"
                  :key="session.id"
                  :class="{
                    'session-deleted': isSessionDeleted(session),
                    'table-active': session.id === selectedSessionId
                  }"
                >
                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionIdentity(session) }">
                    <span v-if="getSessionIdentity(session) !== ''">
                      {{ getSessionIdentity(session) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.create">-->
                  <!--                    {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td class="align-middle text-center">
                    <div class="d-flex flex-column">
                      <div>
                        <small v-if="session.create">
                          {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                      <div>
                        <small v-if="session.expire_at">
                          {{
                            `${moment(session.expire_at * 1000).format('ll')} ${moment(session.expire_at * 1000).format(
                              'LTS'
                            )}`
                          }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                    </div>
                  </td>
                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionStateTypeTranslateForTable(session.state) }"
                  >
                    <span v-if="session.state">
                      {{ getSessionStateTypeTranslateForTable(session.state) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionAuthenStateTypeTranslateForTable(session.AuthenState) }"
                  >
                    <span v-if="session.AuthenState">
                      {{ getSessionAuthenStateTypeTranslateForTable(session.AuthenState) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionAuthenTypeTranslateForTable(getSessionAuthenType(session)) }"
                  >
                    <span v-if="getSessionAuthenType(session)">
                      {{ getSessionAuthenTypeTranslateForTable(getSessionAuthenType(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center">
                    <span v-if="session.hasOwnProperty('cost') && isSessionHasSMSAuthNType(session)">
                      {{ session.cost }} {{ $t('general.rub') }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.profile">-->
                  <!--                    {{ getProfileNameByID(session.profile) }}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td>
                    <i
                      :title="$t('portalSessions.deleteSessionButtonCaption')"
                      v-if="!isSessionDeleted(session)"
                      @click="openDeleteSessionModalWindow(session)"
                      class="fa fa-remove fa-lg mr-q text-danger user-action sessions-table-cursor-pointer"
                    ></i>
                    <i
                      :title="$t('portalSessions.undoDeleteSessionButtonCaption')"
                      v-else
                      @click="openUndoDeleteSessionModalWindow(session)"
                      class="fa fa fa-key fa-lg mr-q text-success user-action sessions-table-cursor-pointer"
                    ></i>
                  </td>
                </tr>
                <tr v-if="!portalSessionsList.length">
                  <td colspan="14" class="no-data-row">
                    <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </transition>
          <transition name="tablesessionstransition">
            <table class="table table-hover" v-if="tableViewType === 'authorizationView'">
              <thead>
                <tr class="sessions-table-cursor-default">
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.identityTableCaption') }}
                  </th>
                  <!--                <th scope="col" class="text-nowrap align-middle text-center">-->
                  <!--                  {{ $t('portalSessions.sessionCreationDate') }}-->
                  <!--                </th>-->
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.sessionCreationExpirationDate') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.stateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.authZStateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.authZTypeTableCaption') }}
                  </th>

                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.authZTimeBeginTableCaption') }}
                  </th>
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.authZTimeEndTableCaption') }}
                  </th>

                  <!--                <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.profile') }}</th>-->
                  <th scope="col" class="text-nowrap align-middle text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :id="session.id"
                  @click="selectSession(session)"
                  class="sessions-table-cursor-default"
                  v-for="session in portalSessionsList"
                  :key="session.id"
                  :class="{
                    'session-deleted': isSessionDeleted(session),
                    'table-active': session.id === selectedSessionId
                  }"
                >
                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionIdentity(session) }">
                    <span v-if="getSessionIdentity(session) !== ''">
                      {{ getSessionIdentity(session) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.create">-->
                  <!--                    {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td class="align-middle text-center">
                    <div class="d-flex flex-column">
                      <div>
                        <small v-if="session.create">
                          {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                      <div>
                        <small v-if="session.expire_at">
                          {{
                            `${moment(session.expire_at * 1000).format('ll')} ${moment(session.expire_at * 1000).format(
                              'LTS'
                            )}`
                          }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                    </div>
                  </td>
                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionStateTypeTranslateForTable(session.state) }"
                  >
                    <span v-if="session.state">
                      {{ getSessionStateTypeTranslateForTable(session.state) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{
                      content: getSessionAuthZStateTypeTranslateForTable(getSessionAuthZState(session))
                    }"
                  >
                    <span v-if="getSessionAuthZState(session)">
                      {{ getSessionAuthZStateTypeTranslateForTable(getSessionAuthZState(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionAuthZTypeTranslateForTable(getSessionAuthZType(session)) }"
                  >
                    <span v-if="getSessionAuthZType(session)">
                      {{ getSessionAuthZTypeTranslateForTable(getSessionAuthZType(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionAuthZTimeBegin(session) }">
                    <span v-if="getSessionAuthZTimeBegin(session) !== ''">
                      {{ getSessionAuthZTimeBegin(session) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionAuthZTimeEnd(session) }">
                    <span v-if="getSessionAuthZTimeEnd(session) !== ''">
                      {{ getSessionAuthZTimeEnd(session) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.profile">-->
                  <!--                    {{ getProfileNameByID(session.profile) }}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td>
                    <i
                      :title="$t('portalSessions.deleteSessionButtonCaption')"
                      v-if="!isSessionDeleted(session)"
                      @click="openDeleteSessionModalWindow(session)"
                      class="fa fa-remove fa-lg mr-q text-danger user-action sessions-table-cursor-pointer"
                    ></i>
                    <i
                      :title="$t('portalSessions.undoDeleteSessionButtonCaption')"
                      v-else
                      @click="openUndoDeleteSessionModalWindow(session)"
                      class="fa fa fa-key fa-lg mr-q text-success user-action sessions-table-cursor-pointer"
                    ></i>
                  </td>
                </tr>
                <tr v-if="!portalSessionsList.length">
                  <td colspan="14" class="no-data-row">
                    <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </transition>
          <transition name="tablesessionstransition">
            <table class="table table-hover" v-if="tableViewType === 'socialNetworksView'">
              <thead>
                <tr class="sessions-table-cursor-default">
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.identityTableCaption') }}
                  </th>
                  <!--                <th scope="col" class="text-nowrap align-middle text-center">-->
                  <!--                  {{ $t('portalSessions.sessionCreationDate') }}-->
                  <!--                </th>-->
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.sessionCreationExpirationDate') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.stateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.authZStateTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.authZTypeTableCaption') }}
                  </th>
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.socialNetworksNameTableCaption') }}
                  </th>
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.socialNetworksIdTableCaption') }}
                  </th>
                  <th scope="col" class="text-nowrap align-middle text-center">
                    {{ $t('portalSessions.socialNetworksIdTableCity') }}
                  </th>
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.socialNetworksIdTableBirthday') }}
                  </th>
                  <th scope="col" class="align-middle text-center">
                    {{ $t('portalSessions.socialNetworksIdTableEmail') }}
                  </th>

                  <th scope="col" class="text-nowrap align-middle text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :id="session.id"
                  @click="selectSession(session)"
                  class="sessions-table-cursor-default"
                  v-for="session in portalSessionsList"
                  :key="session.id"
                  :class="{
                    'session-deleted': isSessionDeleted(session),
                    'table-active': session.id === selectedSessionId
                  }"
                >
                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionIdentity(session) }">
                    <span v-if="getSessionIdentity(session) !== ''">
                      {{ getSessionIdentity(session) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <!--                <td class="align-middle text-center">-->
                  <!--                  <span v-if="session.create">-->
                  <!--                    {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}-->
                  <!--                  </span>-->
                  <!--                  <EmptyCellValue v-else />-->
                  <!--                </td>-->
                  <td class="align-middle text-center">
                    <div class="d-flex flex-column">
                      <div>
                        <small v-if="session.create">
                          {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                      <div>
                        <small v-if="session.expire_at">
                          {{
                            `${moment(session.expire_at * 1000).format('ll')} ${moment(session.expire_at * 1000).format(
                              'LTS'
                            )}`
                          }}
                        </small>
                        <EmptyCellValue v-else />
                      </div>
                    </div>
                  </td>
                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionStateTypeTranslateForTable(session.state) }"
                  >
                    <span v-if="session.state">
                      {{ getSessionStateTypeTranslateForTable(session.state) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{
                      content: getSessionAuthZStateTypeTranslateForTable(getSessionAuthZState(session))
                    }"
                  >
                    <span v-if="getSessionAuthZState(session)">
                      {{ getSessionAuthZStateTypeTranslateForTable(getSessionAuthZState(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td
                    class="align-middle text-center"
                    v-tooltip.right="{ content: getSessionAuthZTypeTranslateForTable(getSessionAuthZType(session)) }"
                  >
                    <span v-if="getSessionAuthZType(session)">
                      {{ getSessionAuthZTypeTranslateForTable(getSessionAuthZType(session)) }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center">
                    <span
                      v-if="
                        getSessionAuthZType(session) === 'vk' ||
                        getSessionAuthZType(session) === 'facebook' ||
                        getSessionAuthZType(session) === 'instagram'
                      "
                    >
                      {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'first_name') }}
                      {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'last_name') }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle text-center">
                    <span
                      v-if="
                        getSessionAuthZType(session) === 'vk' ||
                        getSessionAuthZType(session) === 'facebook' ||
                        getSessionAuthZType(session) === 'instagram'
                      "
                    >
                      <span
                        v-if="
                          getSocialNetworkUrlForUserId(
                            getSessionAuthZType(session),
                            getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'id')
                          )
                        "
                      >
                        <a
                          :href="
                            getSocialNetworkUrlForUserId(
                              getSessionAuthZType(session),
                              getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'id')
                            )
                          "
                        >
                          {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'id') }}
                        </a>
                      </span>
                      <span v-else>
                        {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'id') }}
                      </span>
                    </span>

                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center">
                    <span
                      v-if="
                        (getSessionAuthZType(session) === 'vk' ||
                          getSessionAuthZType(session) === 'facebook' ||
                          getSessionAuthZType(session) === 'instagram') &&
                        getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'city') !== ''
                      "
                    >
                      {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'city') }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center">
                    <span
                      v-if="
                        (getSessionAuthZType(session) === 'vk' ||
                          getSessionAuthZType(session) === 'facebook' ||
                          getSessionAuthZType(session) === 'instagram') &&
                        getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'birthday') !== ''
                      "
                    >
                      {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'birthday') }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td class="align-middle text-center">
                    <span
                      v-if="
                        (getSessionAuthZType(session) === 'vk' ||
                          getSessionAuthZType(session) === 'facebook' ||
                          getSessionAuthZType(session) === 'instagram') &&
                        getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'email') !== ''
                      "
                    >
                      {{ getSessionSocialNetworkObjectField(session, getSessionAuthZType(session), 'email') }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>

                  <td>
                    <i
                      :title="$t('portalSessions.deleteSessionButtonCaption')"
                      v-if="!isSessionDeleted(session)"
                      @click="openDeleteSessionModalWindow(session)"
                      class="fa fa-remove fa-lg mr-q text-danger user-action sessions-table-cursor-pointer"
                    ></i>
                    <i
                      :title="$t('portalSessions.undoDeleteSessionButtonCaption')"
                      v-else
                      @click="openUndoDeleteSessionModalWindow(session)"
                      class="fa fa fa-key fa-lg mr-q text-success user-action sessions-table-cursor-pointer"
                    ></i>
                  </td>
                </tr>
                <tr v-if="!portalSessionsList.length">
                  <td colspan="14" class="no-data-row">
                    <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </transition>
<!--          <transition name="tablesessionstransition">-->
<!--            <table class="table table-hover" v-if="tableViewType === 'subscriptionsView'">-->
<!--              <thead>-->
<!--                <tr class="sessions-table-cursor-default">-->
<!--                  <th scope="col" class="text-nowrap align-middle text-center">-->
<!--                    {{ $t('portalSessions.identityTableCaption') }}-->
<!--                  </th>-->
<!--                  &lt;!&ndash;                <th scope="col" class="text-nowrap align-middle text-center">&ndash;&gt;-->
<!--                  &lt;!&ndash;                  {{ $t('portalSessions.sessionCreationDate') }}&ndash;&gt;-->
<!--                  &lt;!&ndash;                </th>&ndash;&gt;-->
<!--                  <th scope="col" class="align-middle text-center">-->
<!--                    {{ $t('portalSessions.sessionCreationExpirationDate') }}-->
<!--                  </th>-->
<!--                  <th scope="col" class="text-nowrap align-middle text-center">-->
<!--                    {{ $t('portalSessions.stateTableCaption') }}-->
<!--                  </th>-->
<!--                  <th scope="col" class="text-nowrap align-middle text-center">-->
<!--                    {{ $t('portalSessions.authZStateTableCaption') }}-->
<!--                  </th>-->
<!--                  <th scope="col" class="text-nowrap align-middle text-center">-->
<!--                    {{ $t('portalSessions.authZTypeTableCaption') }}-->
<!--                  </th>-->

<!--                  <th scope="col" class="align-middle text-center">-->
<!--                    {{ $t('portalSessions.authZTimeBeginTableCaption') }}-->
<!--                  </th>-->
<!--                  <th scope="col" class="align-middle text-center">-->
<!--                    {{ $t('portalSessions.authZTimeEndTableCaption') }}-->
<!--                  </th>-->

<!--                  &lt;!&ndash;                <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalSessions.profile') }}</th>&ndash;&gt;-->
<!--                  <th scope="col" class="text-nowrap align-middle text-center"></th>-->
<!--                </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--                <tr-->
<!--                  :id="session.id"-->
<!--                  @click="selectSession(session)"-->
<!--                  class="sessions-table-cursor-default"-->
<!--                  v-for="session in portalSessionsList"-->
<!--                  :key="session.id"-->
<!--                  :class="{-->
<!--                    'session-deleted': isSessionDeleted(session),-->
<!--                    'table-active': session.id === selectedSessionId-->
<!--                  }"-->
<!--                >-->
<!--                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionIdentity(session) }">-->
<!--                    <span v-if="getSessionIdentity(session) !== ''">-->
<!--                      {{ getSessionIdentity(session) }}-->
<!--                    </span>-->
<!--                    <EmptyCellValue v-else />-->
<!--                  </td>-->
<!--                  &lt;!&ndash;                <td class="align-middle text-center">&ndash;&gt;-->
<!--                  &lt;!&ndash;                  <span v-if="session.create">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </span>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  <EmptyCellValue v-else />&ndash;&gt;-->
<!--                  &lt;!&ndash;                </td>&ndash;&gt;-->
<!--                  <td class="align-middle text-center">-->
<!--                    <div class="d-flex flex-column">-->
<!--                      <div>-->
<!--                        <small v-if="session.create">-->
<!--                          {{ `${moment(session.create).format('ll')} ${moment(session.create).format('LTS')}` }}-->
<!--                        </small>-->
<!--                        <EmptyCellValue v-else />-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <small v-if="session.expire_at">-->
<!--                          {{-->
<!--                            `${moment(session.expire_at * 1000).format('ll')} ${moment(session.expire_at * 1000).format(-->
<!--                              'LTS'-->
<!--                            )}`-->
<!--                          }}-->
<!--                        </small>-->
<!--                        <EmptyCellValue v-else />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td-->
<!--                    class="align-middle text-center"-->
<!--                    v-tooltip.right="{ content: getSessionStateTypeTranslateForTable(session.state) }"-->
<!--                  >-->
<!--                    <span v-if="session.state">-->
<!--                      {{ getSessionStateTypeTranslateForTable(session.state) }}-->
<!--                    </span>-->
<!--                    <EmptyCellValue v-else />-->
<!--                  </td>-->

<!--                  <td-->
<!--                    class="align-middle text-center"-->
<!--                    v-tooltip.right="{-->
<!--                      content: getSessionAuthZStateTypeTranslateForTable(getSessionAuthZState(session))-->
<!--                    }"-->
<!--                  >-->
<!--                    <span v-if="getSessionAuthZState(session)">-->
<!--                      {{ getSessionAuthZStateTypeTranslateForTable(getSessionAuthZState(session)) }}-->
<!--                    </span>-->
<!--                    <EmptyCellValue v-else />-->
<!--                  </td>-->

<!--                  <td-->
<!--                    class="align-middle text-center"-->
<!--                    v-tooltip.right="{ content: getSessionAuthZTypeTranslateForTable(getSessionAuthZType(session)) }"-->
<!--                  >-->
<!--                    <span v-if="getSessionAuthZType(session)">-->
<!--                      {{ getSessionAuthZTypeTranslateForTable(getSessionAuthZType(session)) }}-->
<!--                    </span>-->
<!--                    <EmptyCellValue v-else />-->
<!--                  </td>-->

<!--                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionAuthZTimeBegin(session) }">-->
<!--                    <span v-if="getSessionAuthZTimeBegin(session) !== ''">-->
<!--                      {{ getSessionAuthZTimeBegin(session) }}-->
<!--                    </span>-->
<!--                    <EmptyCellValue v-else />-->
<!--                  </td>-->

<!--                  <td class="align-middle text-center" v-tooltip.right="{ content: getSessionAuthZTimeEnd(session) }">-->
<!--                    <span v-if="getSessionAuthZTimeEnd(session) !== ''">-->
<!--                      {{ getSessionAuthZTimeEnd(session) }}-->
<!--                    </span>-->
<!--                    <EmptyCellValue v-else />-->
<!--                  </td>-->

<!--                  &lt;!&ndash;                <td class="align-middle text-center">&ndash;&gt;-->
<!--                  &lt;!&ndash;                  <span v-if="session.profile">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    {{ getProfileNameByID(session.profile) }}&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </span>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  <EmptyCellValue v-else />&ndash;&gt;-->
<!--                  &lt;!&ndash;                </td>&ndash;&gt;-->
<!--                  <td>-->
<!--                    <i-->
<!--                      :title="$t('portalSessions.deleteSessionButtonCaption')"-->
<!--                      v-if="!isSessionDeleted(session)"-->
<!--                      @click="openDeleteSessionModalWindow(session)"-->
<!--                      class="fa fa-remove fa-lg mr-q text-danger user-action sessions-table-cursor-pointer"-->
<!--                    ></i>-->
<!--                    <i-->
<!--                      :title="$t('portalSessions.undoDeleteSessionButtonCaption')"-->
<!--                      v-else-->
<!--                      @click="openUndoDeleteSessionModalWindow(session)"-->
<!--                      class="fa fa fa-key fa-lg mr-q text-success user-action sessions-table-cursor-pointer"-->
<!--                    ></i>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr v-if="!portalSessionsList.length">-->
<!--                  <td colspan="14" class="no-data-row">-->
<!--                    <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>-->
<!--                  </td>-->
<!--                </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </transition>-->
          <!-- </div>
          </div> -->
          <div
            v-if="
              this.canLoadMorePortalSessions &&
              this.portalSessionsList.length &&
              this.totalResultPortalSessions - this.portalSessionsList.length
            "
            class="card-block text-center p-0"
          >
            <WButton fullWidth
              @click="loadMore"
            >
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultPortalSessions - this.portalSessionsList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultPortalSessions - this.portalSessionsList.length }}</span>
              {{ this.$t('portal.paginationUserAccountsOutOf') }}
              {{ this.totalResultPortalSessions - this.portalSessionsList.length }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="isDeleteSessionModalOpen"
      title="Delete Session"
      class="modal-danger"
      okText="Confirm"
      cancelText="Cancel"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('portalSessions.deleteSessionModalHeader') }}</h4>

      <div>
        <p>{{ $t('portalSessions.deleteSessionModalConfirm') }}?</p>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelClickInDeleteSessionModalWindow"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteSessionAndCloseDeleteSessionModalWindow(updatedSession)"
        >
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>
    <Modal
      v-model="isUndoDeleteSessionModalOpen"
      title="Delete Session"
      class="modal-info"
      okText="Confirm"
      cancelText="Cancel"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('portalSessions.undoDeleteSessionModalHeader') }}</h4>

      <div>
        <p>{{ $t('portalSessions.undoDeleteSessionModalConfirm') }}?</p>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelClickInUndoDeleteSessionModalWindow"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="undoDeleteSessionAndCloseUndoDeleteSessionModalWindow(updatedSession)"
        >
          {{ $t('portalSessions.undoDeleteSessionModalUndoButton') }}
        </WButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { mask } from 'vue-the-mask';
import { Multiselect } from 'vue-multiselect';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import DatePicker from '../../components/date-picker.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import helpers from '../../helpers';
import portalProfilesService from '../../services/portalProfilesService';
import portalSessionsService from '../../services/portalSessionsService';
import TablePaginationV3ForPortalSessions from '../../components/table-pagination-v3-for-portal-sessions.vue';
import cpeService from '../../services/cpeService';
import wlanService from '../../services/wlanService';
import Info from '../../components/Universal/info-icon.vue';
import Modal from '../../components/Modal.vue';
import commonService from '../../services/commonService';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

const SOCIALS = ['vk', 'facebook', 'instagram', 'unset'];

export default {
  name: 'PortalSessions',
  components: {
    DatePicker,
    EmptyCellValue,
    TablePaginationV3ForPortalSessions,
    Multiselect,
    CheckboxComponent,
    MultiSelectComponent,
    Info,
    Modal
  },
  directives: { mask },
  data() {
    return {
      showTablePaginationV3Component: true,
      delayTimer: null,
      userAccountsData: [], // используется чтобы доставить информацию о подписках
      filterSettings: {
        // тут храним дополнительные настройки для запросов q, которые хранятся в  filters.q
        // и изменяются в TablePaginationV2ForPortalSessions

        // Статус сессии
        state: '$eq',
        // статус идентификации
        AuthenState: '$eq',
        // тип идентификации
        authenType: '$eq',
        // статус авторизации
        authZState: '$eq',
        // тип авторизации
        authZType: '$eq',
        // фильтры по времени
        enablingDateTimeFilters: {
          // Время создания сессии
          create_at: false,
          // Время истечения сессии
          expire_at: false,
          // Начало авторизации пользователя
          start_at: false,
          // Окончание авторизации пользователя
          stop_at: false
        }
      },
      buttonsAreVisible: true,
      selectedSessionId: '',
      // authZStates: {
      //   none: this.$t('portalSessions.authZStatenone'),
      //   checked: this.$t('portalSessions.authZStatechecked')
      // },
      // authenTypes: {
      //   callfront: this.$t('portalSessions.authenTypeCallfront'),
      //   callback: this.$t('portalSessions.authenTypeCallback'),
      //   userpass: this.$t('portalSessions.authenTypeUserpass'),
      //   esia: this.$t('portalSessions.authenTypeEsia'),
      //   email: this.$t('portalSessions.authenTypeEmail'),
      //   sms: this.$t('portalSessions.authenTypeSms')
      // },
      // authenStates: {
      //   sent: this.$t('portalSessions.authenStatesent'),
      //   need: this.$t('portalSessions.authenStateneed'),
      //   checked: this.$t('portalSessions.authenStatechecked')
      // },
      // typesOfTableViews: ['standardView', 'authenticationView', 'authorizationView', 'socialNetworksView', 'subscriptionsView'],
      typesOfTableViews: ['standardView', 'authenticationView', 'authorizationView', 'socialNetworksView'],
      tableViewType: 'standardView', // задаем вид отображения для таблицы (стандартный, соц сети, аутентификация, авторизация , подписки)
      isDeleteSessionModalOpen: false,
      isUndoDeleteSessionModalOpen: false,
      updatedSession: undefined,
      selectedProfileInMultiselect: [],
      selectedProfileInPortalUserAccounts: { name: this.$t('portalStats.allProfiles'), id: '' },
      selectedLocationInPortalUserAccounts: { name: this.$t('portalStats.allLocations'), id: '' },
      isShow: false,
      showTransactions: false,
      show: {
        vk: false,
        facebook: false
      },
      // drPickerDate: {
      //   startDate: moment().startOf('week').valueOf(),
      //   endDate: moment().endOf('week').valueOf()
      // },
      offset: 0,
      canLoadMorePortalSessions: true,
      totalResultPortalSessions: false,
      isModalOpen: false,
      isCloseModalOpen: false,
      isDeleteOpen: false,
      isNew: true,
      account: {} /* setting initial value to false breaks something */,
      newAccount: {
        profile: '',
        identity: '',
        balance: '',
        name: '',
        surname: '',
        id: ''
      },
      visitsGreaterLessOperator: '$gt',
      filterLocation: {
        with_childs: true
      },
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'create_at',
        sort_order: -1 /* 1 for ascending, -1 for descending */,
        search: '',
        profiles: [],
        location: {
          data: {},
          with_childs: true
        },
        date: {
          startDate: moment().startOf('week').valueOf(),
          endDate: moment().endOf('week').valueOf()
        },
        q: {
          social_network: '',
          state: '',
          AuthenState: '',
          // authentication: {type: ''},
          authenticationType: '',
          // authorization: {state: '', type: ''},
          authorizationState: '',
          authorizationType: '',
          is_deleted: '',
          create_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          },
          expire_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          },
          start_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          },
          stop_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          }
        }
      }
    };
  },
  methods: {
     getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    // resetAllFilters() {
    //    this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'create_at',
    //     sort_order: -1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     profiles: [],
    //     location: {
    //       data: {},
    //       with_childs: true
    //     },
    //     date: {
    //       startDate: moment().startOf('week').valueOf(),
    //       endDate: moment().endOf('week').valueOf()
    //     },
    //     q: {
    //       social_network: '',
    //       state: '',
    //       AuthenState: '',
    //       // authentication: {type: ''},
    //       authenticationType: '',
    //       // authorization: {state: '', type: ''},
    //       authorizationState: '',
    //       authorizationType: '',
    //       is_deleted: '',
    //       create_at: {
    //         startDate: moment().startOf('month').valueOf(),
    //         endDate: moment().endOf('day').valueOf()
    //       },
    //       expire_at: {
    //         startDate: moment().startOf('month').valueOf(),
    //         endDate: moment().endOf('day').valueOf()
    //       },
    //       start_at: {
    //         startDate: moment().startOf('month').valueOf(),
    //         endDate: moment().endOf('day').valueOf()
    //       },
    //       stop_at: {
    //         startDate: moment().startOf('month').valueOf(),
    //         endDate: moment().endOf('day').valueOf()
    //       }
    //     }
    //   }
    //   this.filterSettings = {
    //     // тут храним дополнительные настройки для запросов q, которые хранятся в  filters.q
    //     // и изменяются в TablePaginationV2ForPortalSessions
    //
    //     // Статус сессии
    //     state: '$eq',
    //     // статус идентификации
    //     AuthenState: '$eq',
    //     // тип идентификации
    //     authenType: '$eq',
    //     // статус авторизации
    //     authZState: '$eq',
    //     // тип авторизации
    //     authZType: '$eq',
    //     // фильтры по времени
    //     enablingDateTimeFilters: {
    //       // Время создания сессии
    //       create_at: false,
    //       // Время истечения сессии
    //       expire_at: false,
    //       // Начало авторизации пользователя
    //       start_at: false,
    //       // Окончание авторизации пользователя
    //       stop_at: false
    //     }
    //   }
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters() {
       this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'create_at',
        sort_order: -1 /* 1 for ascending, -1 for descending */,
        search: '',
        profiles: [],
        // location: {
        //   data: {},
        //   with_childs: true
        // },
        date: {
          startDate: moment().startOf('week').valueOf(),
          endDate: moment().endOf('week').valueOf()
        },
        q: {
          social_network: '',
          state: '',
          AuthenState: '',
          // authentication: {type: ''},
          authenticationType: '',
          // authorization: {state: '', type: ''},
          authorizationState: '',
          authorizationType: '',
          is_deleted: '',
          create_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          },
          expire_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          },
          start_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          },
          stop_at: {
            startDate: moment().startOf('month').valueOf(),
            endDate: moment().endOf('day').valueOf()
          }
        }
      }
      this.filterSettings = {
        // тут храним дополнительные настройки для запросов q, которые хранятся в  filters.q
        // и изменяются в TablePaginationV2ForPortalSessions

        // Статус сессии
        state: '$eq',
        // статус идентификации
        AuthenState: '$eq',
        // тип идентификации
        authenType: '$eq',
        // статус авторизации
        authZState: '$eq',
        // тип авторизации
        authZType: '$eq',
        // фильтры по времени
        enablingDateTimeFilters: {
          // Время создания сессии
          create_at: false,
          // Время истечения сессии
          expire_at: false,
          // Начало авторизации пользователя
          start_at: false,
          // Окончание авторизации пользователя
          stop_at: false
        }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }

      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    emitFilterSettingsHandler(setting) {
      const filter = Object.keys(setting)[0];
      // console.log(filter);
      this.filterSettings[filter] = setting[filter];
      // console.log(this.filterSettings)
    },
    // isInViewport(elementId) {
    //   const element = document.getElementById(elementId);
    //   const wrapper = document.getElementById('container-fluid')
    //   if (!element || !wrapper) {
    //     return '' ;
    //   }
    //   const wrapperRect = wrapper.getBoundingClientRect()
    //   const rect = element.getBoundingClientRect();
    //   console.log(rect)
    //   console.log(wrapperRect)
    //   return (
    //     rect.top >= 0 &&
    //     rect.left >= 0 &&
    //     rect.bottom <= wrapperRect.bottom &&
    //     rect.right <= wrapperRect.right
    //   )
    // },
    addScrollListener() {
      const container = document.getElementById('container-fluid');
      if (container) {
        container.addEventListener('scroll', this.visabiltyCheckerWhenScroll);
      }
    },
    removeScrollListener() {
      const container = document.getElementById('container-fluid');
      if (container) {
        container.removeEventListener('scroll', this.visabiltyCheckerWhenScroll);
      }
    },
    visabiltyCheckerWhenScroll() {
      // console.log('scrolled')
      const elemId = 'viewsChangeButtons';
      if (this.checkVisibility(elemId)) {
        // console.log('set true')
        this.buttonsAreVisible = true;
      } else {
        // console.log('set false')
        this.buttonsAreVisible = false;
      }
    },
    checkVisibility(elemId) {
      const elem = document.getElementById(elemId);
      const wrapper = document.getElementById('container-fluid');
      if (!elem || !wrapper) {
        return '';
      }
      const wrapperRect = wrapper.getBoundingClientRect();

      // console.log(wrapperRect)
      const rect = elem.getBoundingClientRect();
      // console.log(rect)
      const viewHeight = Math.max(wrapperRect.height);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    },
    getCpeIdFromSession(session) {
      try {
        if (Object.prototype.hasOwnProperty.call(session, 'cpe')) {
          return session.cpe;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getWlanIdFromSession(session) {
      try {
        if (Object.prototype.hasOwnProperty.call(session, 'wlan_id')) {
          return session.wlan_id;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    // getCpeName(id) {
    //   return commonService.cpeNamebyId(id);
    // },
    // getWlanName(id) {
    //   return commonService.wlanNamebyId(id);
    // },
    // reInitializei18ns() {
    //   this.authenTypes = {
    //     callfront: this.$t('portalSessions.authenTypeCallfront'),
    //     callback: this.$t('portalSessions.authenTypeCallback'),
    //     userpass: this.$t('portalSessions.authenTypeUserpass'),
    //     esia: this.$t('portalSessions.authenTypeEsia'),
    //     email: this.$t('portalSessions.authenTypeEmail'),
    //     sms: this.$t('portalSessions.authenTypeSms')
    //   };
    //   this.authenStates = {
    //     sent: this.$t('portalSessions.authenStatesent'),
    //     need: this.$t('portalSessions.authenStateneed'),
    //     checked: this.$t('portalSessions.authenStatechecked')
    //   };
    //   this.typesOfTableViews = {
    //     standardView: this.$t('portalSessions.tableViewTypeStandard'),
    //     authenticationView: this.$t('portalSessions.tableViewTypeAuthentication'),
    //     authorizationView: this.$t('portalSessions.tableViewTypeAuthorization'),
    //     socialNetworksView: this.$t('portalSessions.tableViewTypeSocialNetworks')
    //   }
    //   this.authZStates = {
    //     none: this.$t('portalSessions.authZStatenone'),
    //     checked: this.$t('portalSessions.authZStatechecked')
    //   }
    // },
    getSocialNetworkUrlForUserId(socialNetworkName, id) {
      try {
        if (socialNetworkName === 'vk') {
          return `https://vk.com/id${id}`;
        } if (socialNetworkName === 'facebook') {
          return `https://www.facebook.com/profile.php?id=${id}`;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    selectSession(session) {
      if (this.selectedSessionId === '' || this.selectedSessionId !== session.id) {
        this.selectedSessionId = session.id;
        return;
      }
      if (this.selectedSessionId === session.id) {
        this.selectedSessionId = '';
      }
    },
    changeTableViewType(viewType) {
      let contanierscrollY = 0;
      const container = document.getElementById('container-fluid');
      if (container) {
        // console.log(container.scrollTop)
        contanierscrollY = container.scrollTop;
      }
      this.tableViewType = viewType;

      setTimeout(() => {
        if (container) {
          container.scroll(0, contanierscrollY);
        }
      }, 100);

      // пробуем прокуртится к выбраному элементу, если не вышло через 1500 мс, пробум еще раз через 1500
      setTimeout(() => {
        // console.log(this.selectedSessionId)
        if (this.selectedSessionId !== '') {
          const selectedSessionInDom = document.getElementById(this.selectedSessionId);
          // console.log(selectedSessionInDom)
          if (selectedSessionInDom) {
            setTimeout(() => {
              selectedSessionInDom.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }, 10);
          } else {
            setTimeout(() => {
              if (this.selectedSessionId !== '') {
                const selectedSessionInDom = document.getElementById(this.selectedSessionId);
                if (selectedSessionInDom) {
                  setTimeout(() => {
                    selectedSessionInDom.scrollIntoView({ block: 'center', behavior: 'smooth' });
                  }, 10);
                }
              }
            }, 1500);
          }
        }
      }, 1500);
    },
    getSessionIdentity(session) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(session, 'authentication') &&
          typeof session.authentication === 'object' &&
          Object.prototype.hasOwnProperty.call(session.authentication, 'identity')
        ) {
          return session.authentication.identity;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getSessionStateTypeTranslateForTable(stateType) {
      try {
        return this.$t(`portalSessions.stateType${stateType}`);
      } catch (e) {
        return stateType;
      }
    },
    getSessionAuthenStateTypeTranslateForTable(authenStateType) {
      try {
        return this.$t(`portalSessions.authenState${authenStateType}`);
      } catch (e) {
        return authenStateType;
      }
    },
    getSessionAuthZStateTypeTranslateForTable(authZStateType) {
      try {
        return this.$t(`portalSessions.authZState${authZStateType}`);
      } catch (e) {
        return authZStateType;
      }
    },
    getSessionAuthenTypeTranslateForTable(authenType) {
      try {
        if (authenType === '') {
          return '';
        }
        return this.$t(`portalSessions.authenType${authenType}`);
      } catch (e) {
        return authenType;
      }
    },
    getSessionAuthZTypeTranslateForTable(authZType) {
      try {
        if (authZType === '') {
          return '';
        }
        return this.$t(`portalSessions.authZType${authZType}`);
      } catch (e) {
        return authZType;
      }
    },
    getSessionAuthZTimeBegin(session) {
      try {
        if (Object.prototype.hasOwnProperty.call(session, 'start_at')) {
          if (session.start_at === 0) {
            return '';
          }
          const beginTime = session.start_at * 1000;
          return `${moment(beginTime).format('ll')} ${moment(beginTime).format('LTS')}`;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getSessionAuthZTimeEnd(session) {
      try {
        if (Object.prototype.hasOwnProperty.call(session, 'stop_at')) {
          if (session.stop_at === 0) {
            return '';
          }
          const endTime = session.stop_at * 1000;
          return `${moment(endTime).format('ll')} ${moment(endTime).format('LTS')}`;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getSessionAuthenType(session) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(session, 'authentication') &&
          typeof session.authentication === 'object' &&
          Object.prototype.hasOwnProperty.call(session.authentication, 'type')
        ) {
          if (session.AuthenState !== 'need') {
            return session.authentication.type;
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getSessionAuthZType(session) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(session, 'authorization') &&
          typeof session.authorization === 'object' &&
          Object.prototype.hasOwnProperty.call(session.authorization, 'type')
        ) {
          if (this.getSessionAuthZState(session) !== 'none') {
            return session.authorization.type;
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getSessionSocialNetworkObjectField(session, socialNetworkName, field) {
      if (!socialNetworkName || !socialNetworkName || !field) {
        return '';
      }
      try {
        const fieldName = field;
        if (
          Object.prototype.hasOwnProperty.call(session, 'social_network') &&
          typeof session.social_network === 'object' &&
          Object.prototype.hasOwnProperty.call(session.social_network, socialNetworkName) &&
          typeof session.social_network[socialNetworkName] === 'object'
        ) {
          const socialNetworkData = session.social_network[socialNetworkName];
          // console.log(socialNetworkData)
          if (Object.prototype.hasOwnProperty.call(socialNetworkData, fieldName)) {
            return socialNetworkData[fieldName];
          }
          return '';
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    getSessionAuthZState(session) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(session, 'authorization') &&
          typeof session.authorization === 'object' &&
          Object.prototype.hasOwnProperty.call(session.authorization, 'state')
        ) {
          // console.log(session.authorization.state)
          return session.authorization.state;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    isSessionHasSMSAuthNType(session) {
      // используется для проверки смс ли аутентификация была в этой сессии, чтобы понять показывать стоиомсть смс или нет
      if (
        session.hasOwnProperty('authentication') &&
        session.authentication.hasOwnProperty('type') &&
        session.authentication.type === 'sms' &&
        session.AuthenState !== 'need'
      ) {
        return true;
      }
      return false;
    },
    async requestProfiles(searchQuery) {
      return portalProfilesService.requestProfiles(searchQuery);
    },
    toggleWithChilds() {
      this.filterLocation.with_childs = !this.filterLocation.with_childs;
    },
    locationSelectedFromSelectorInSlot() {
      // console.log(this.$refs.filterProfile.value);
      const locationId = this.$refs.filterLocation.value;
      if (locationId === '' || locationId === undefined) {
        this.selectedLocationInPortalUserAccounts = { name: this.$t('portalStats.allProfiles'), id: '' };
        this.filterLocation.with_childs = true;
      } else {
        const selectedLocationName =
          this.locationsListById[locationId] && this.locationsListById[locationId].name ?
            this.locationsListById[locationId].name :
            this.$t('portalStats.allLocations');
        const selectedLocationId =
          this.locationsListById[locationId] && this.locationsListById[locationId].id ?
            this.locationsListById[locationId].id :
            '';
        this.selectedLocationInPortalUserAccounts = { name: selectedLocationName, id: selectedLocationId };
      }
      // this.getWithQuery();
    },
    profileSelectedFromSelectorInSlot() {
      // console.log(this.$refs.filterProfile.value);
      const profileId = this.$refs.filterProfile.value;
      if (profileId === '' || profileId === undefined) {
        this.selectedProfileInPortalUserAccounts = { name: this.$t('portalStats.allProfiles'), id: '' };
      } else {
        const selectedProfileName =
          this.portalProfilesListById[profileId] && this.portalProfilesListById[profileId].name ?
            this.portalProfilesListById[profileId].name :
            this.$t('portalStats.allProfiles');
        const selectedProfileId =
          this.portalProfilesListById[profileId] && this.portalProfilesListById[profileId].id ?
            this.portalProfilesListById[profileId].id :
            '';
        this.selectedProfileInPortalUserAccounts = { name: selectedProfileName, id: selectedProfileId };
      }
    },
    getProfileNameByID(id) {
      if (this.portalProfilesList) {
        const result = this.portalProfilesList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    getCpeName(id) {
      const cpesList = this.$store.state.cpesList || [];
      let foundCpe = '';
      for (const cpe of cpesList) {
        if (cpe.id === id) {
          if (cpe.name) {
            foundCpe = cpe.name;
          } else if (cpe.model.name) {
            foundCpe = cpe.model.name;
          } else if (cpe.id) {
            foundCpe = 'no data';
          } else {
            foundCpe = this.$t('general.unset');
          }
          break;
        }
      }
      return foundCpe;
    },
    getWlanName(id) {
      const wlansList = this.$store.state.wlansList || [];
      let foundWlan = '';

      for (const wlan of wlansList) {
        if (wlan.id === id) {
          if (wlan.ssid) {
            foundWlan = wlan.ssid;
          } else if (wlan.id) {
            foundWlan = 'no data';
          } else {
            foundWlan = this.$t('general.unset');
          }
          break;
        }
      }
      return foundWlan;
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      portalSessionsService.getSessionsWithQueryV2(this, { query: true });
    },
    clearQuery() {
      this.selectedSessionId = '';
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMorePortalSessions = true;
      this.$store.commit('portalSessionsListClean');
    },

    getUserAccountByIds(account) {
      portalSessionsService.getUserAccountById(this, account);
    },
    getFormatMac: helpers.getFormatMac,
    // getWithQuery() {
    //   this.clearQuery();
    //   portalSessionsService.getSessionsWithQueryV2(this, { query: true });
    // },
    getWithQuery() {
      this.clearQuery();
      this.$nextTick(()=>{
         portalSessionsService.getSessionsWithQueryV2(this, { query: true });
      })
    },
    formatPortalSessions() {
      if (this.tableViewType === 'standardView') {
        return this.formatPortalSessionsForStandardView();
      } if (this.tableViewType === 'authenticationView') {
        return this.formatPortalSessionsForAuthenticationView();
      } if (this.tableViewType === 'authorizationView') {
        return this.formatPortalSessionsForAuthorizationView();
      } if (this.tableViewType === 'socialNetworksView') {
        return this.formatPortalSessionsForSocialNetworksView();
      }
      // if (this.tableViewType === 'subscriptionsView') {
      //   return this.formatPortalSessionsForSubscriptionsView();
      // }
      return this.formatPortalSessionsForStandardView();
    },
    formatPortalSessionsForStandardView() {
      const result = [];
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.userAccountsList) {
        const accountObj = {};
        accountObj[this.$t('portalSessions.identityTableCaption')] = '—';
        if (this.getSessionIdentity(account)) {
          accountObj[this.$t('portalSessions.identityTableCaption')] = this.getSessionIdentity(account);
        }
        accountObj[this.$t('portalSessions.stateTableCaption')] = '—';
        if (this.getSessionStateTypeTranslateForTable(account.state)) {
          accountObj[this.$t('portalSessions.stateTableCaption')] = this.getSessionStateTypeTranslateForTable(
            account.state
          );
        }
        accountObj[this.$t('general.type')] = '—';
        if (account.ua.type) {
          accountObj[this.$t('general.type')] = this.$t(`portalSessions.ua.${account.ua.type}`);
        }
        accountObj[this.$t('portalSessions.creationSessionForTable')] = '—';
        if (account.create) {
          accountObj[this.$t('portalSessions.creationSessionForTable')] = `${moment(account.create).format(
            'll'
          )} ${moment(account.create).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.expirationSessionForTable')] = '—';
        if (account.expire_at) {
          accountObj[this.$t('portalSessions.expirationSessionForTable')] = `${moment(account.expire_at * 1000).format(
            'll'
          )} ${moment(account.expire_at * 1000).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.mac')] = '—';
        if (account.mac) {
          accountObj[this.$t('portalSessions.mac')] = account.mac;
        }
        accountObj[this.$t('portalSessions.ip')] = '—';
        if (account.ip) {
          accountObj[this.$t('portalSessions.ip')] = account.ip;
        }
        accountObj[this.$t('portalSessions.os')] = '—';
        if (account.ua.os) {
          accountObj[this.$t('portalSessions.os')] = account.ua.os;
        }
        accountObj[this.$t('portalSessions.browser')] = '—';
        if (account.ua.name) {
          accountObj[this.$t('portalSessions.browser')] = account.ua.name;
        }
        accountObj[this.$t('portalSessions.profile')] = '—';
        if (account.profile) {
          accountObj[this.$t('portalSessions.profile')] = this.getProfileNameByID(account.profile);
        }
        accountObj[this.$t('portalSessions.cpe')] = '—';
        if (account.cpe) {
          accountObj[this.$t('portalSessions.cpe')] = this.getCpeName(account.cpe);
        }
        accountObj[this.$t('portalSessions.nas_id')] = '—';
        if (account.nas_id) {
          accountObj[this.$t('portalSessions.nas_id')] = account.nas_id;
        }
        accountObj[this.$t('portalSessions.wlan')] = '—';
        if (account.wlan_id) {
          accountObj[this.$t('portalSessions.wlan')] = this.getWlanName(account.wlan_id);
        }

        // accountObj[this.$t('portalSessions.costForTable')] = '—';
        // if (account.hasOwnProperty('cost') && this.isSessionHasSMSAuthNType(account)) {
        //   accountObj[this.$t('portalSessions.costForTable')] = `${account.cost} ${this.$t('general.rub')}`;
        // }
        accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
          'portalSessions.isDeletedForDownloadsNo'
        );
        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
            'portalSessions.isDeletedForDownloadsYes'
          );
        }
        result.push(accountObj);
      });
      return result;
    },
    formatPortalSessionsForAuthenticationView() {
      const result = [];
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.userAccountsList) {
        const accountObj = {};
        accountObj[this.$t('portalSessions.identityTableCaption')] = '—';
        if (this.getSessionIdentity(account)) {
          accountObj[this.$t('portalSessions.identityTableCaption')] = this.getSessionIdentity(account);
        }
        accountObj[this.$t('portalSessions.creationSessionForTable')] = '—';
        if (account.create) {
          accountObj[this.$t('portalSessions.creationSessionForTable')] = `${moment(account.create).format(
            'll'
          )} ${moment(account.create).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.expirationSessionForTable')] = '—';
        if (account.expire_at) {
          accountObj[this.$t('portalSessions.expirationSessionForTable')] = `${moment(account.expire_at * 1000).format(
            'll'
          )} ${moment(account.expire_at * 1000).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.stateTableCaption')] = '—';
        if (this.getSessionStateTypeTranslateForTable(account.state)) {
          accountObj[this.$t('portalSessions.stateTableCaption')] = this.getSessionStateTypeTranslateForTable(
            account.state
          );
        }

        accountObj[this.$t('portalSessions.authenStateTableCaption')] = '—';
        if (account.AuthenState) {
          accountObj[
            this.$t('portalSessions.authenStateTableCaption')
          ] = this.getSessionAuthenStateTypeTranslateForTable(account.AuthenState);
        }

        accountObj[this.$t('portalSessions.authenTypeTableCaption')] = '—';
        if (this.getSessionAuthenType(account)) {
          accountObj[this.$t('portalSessions.authenTypeTableCaption')] = this.getSessionAuthenTypeTranslateForTable(
            this.getSessionAuthenType(account)
          );
        }

        accountObj[this.$t('portalSessions.costForTable')] = '—';
        if (account.hasOwnProperty('cost') && this.isSessionHasSMSAuthNType(account)) {
          accountObj[this.$t('portalSessions.costForTable')] = `${account.cost} ${this.$t('general.rub')}`;
        }
        accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
          'portalSessions.isDeletedForDownloadsNo'
        );
        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
            'portalSessions.isDeletedForDownloadsYes'
          );
        }
        result.push(accountObj);
      });
      return result;
    },
    formatPortalSessionsForAuthorizationView() {
      const result = [];
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.userAccountsList) {
        const accountObj = {};
        accountObj[this.$t('portalSessions.identityTableCaption')] = '—';
        if (this.getSessionIdentity(account)) {
          accountObj[this.$t('portalSessions.identityTableCaption')] = this.getSessionIdentity(account);
        }
        accountObj[this.$t('portalSessions.creationSessionForTable')] = '—';
        if (account.create) {
          accountObj[this.$t('portalSessions.creationSessionForTable')] = `${moment(account.create).format(
            'll'
          )} ${moment(account.create).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.expirationSessionForTable')] = '—';
        if (account.expire_at) {
          accountObj[this.$t('portalSessions.expirationSessionForTable')] = `${moment(account.expire_at * 1000).format(
            'll'
          )} ${moment(account.expire_at * 1000).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.stateTableCaption')] = '—';
        if (this.getSessionStateTypeTranslateForTable(account.state)) {
          accountObj[this.$t('portalSessions.stateTableCaption')] = this.getSessionStateTypeTranslateForTable(
            account.state
          );
        }

        accountObj[this.$t('portalSessions.authZStateTableCaption')] = '—';
        if (this.getSessionAuthZState(account)) {
          accountObj[this.$t('portalSessions.authZStateTableCaption')] = this.getSessionAuthZStateTypeTranslateForTable(
            this.getSessionAuthZState(account)
          );
        }

        accountObj[this.$t('portalSessions.authZTypeTableCaption')] = '—';
        if (this.getSessionAuthZType(account)) {
          accountObj[this.$t('portalSessions.authZTypeTableCaption')] = this.getSessionAuthZTypeTranslateForTable(
            this.getSessionAuthZType(account)
          );
        }

        accountObj[this.$t('portalSessions.authZTimeBeginTableCaption')] = '—';
        if (this.getSessionAuthZTimeBegin(account) !== '') {
          accountObj[this.$t('portalSessions.authZTimeBeginTableCaption')] = this.getSessionAuthZTimeBegin(account);
        }

        accountObj[this.$t('portalSessions.authZTimeEndTableCaption')] = '—';
        if (this.getSessionAuthZTimeEnd(account) !== '') {
          accountObj[this.$t('portalSessions.authZTimeEndTableCaption')] = this.getSessionAuthZTimeEnd(account);
        }

        accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
          'portalSessions.isDeletedForDownloadsNo'
        );
        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
            'portalSessions.isDeletedForDownloadsYes'
          );
        }
        result.push(accountObj);
      });
      return result;
    },

    // formatPortalSessionsForSubscriptionsView() {
    //   const result = [];
    //   this.portalSessionsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     const accountObj = {};
    //     accountObj[this.$t('portalSessions.identityTableCaption')] = '—';
    //     if (this.getSessionIdentity(account)) {
    //       accountObj[this.$t('portalSessions.identityTableCaption')] = this.getSessionIdentity(account);
    //     }
    //     accountObj[this.$t('portalSessions.creationSessionForTable')] = '—';
    //     if (account.create) {
    //       accountObj[this.$t('portalSessions.creationSessionForTable')] = `${moment(account.create).format(
    //         'll'
    //       )} ${moment(account.create).format('LTS')}`;
    //     }
    //     accountObj[this.$t('portalSessions.expirationSessionForTable')] = '—';
    //     if (account.expire_at) {
    //       accountObj[this.$t('portalSessions.expirationSessionForTable')] = `${moment(account.expire_at * 1000).format(
    //         'll'
    //       )} ${moment(account.expire_at * 1000).format('LTS')}`;
    //     }
    //     accountObj[this.$t('portalSessions.stateTableCaption')] = '—';
    //     if (this.getSessionStateTypeTranslateForTable(account.state)) {
    //       accountObj[this.$t('portalSessions.stateTableCaption')] = this.getSessionStateTypeTranslateForTable(
    //         account.state
    //       );
    //     }
    //
    //     accountObj[this.$t('portalSessions.authZStateTableCaption')] = '—';
    //     if (this.getSessionAuthZState(account)) {
    //       accountObj[this.$t('portalSessions.authZStateTableCaption')] = this.getSessionAuthZStateTypeTranslateForTable(
    //         this.getSessionAuthZState(account)
    //       );
    //     }
    //
    //     accountObj[this.$t('portalSessions.authZTypeTableCaption')] = '—';
    //     if (this.getSessionAuthZType(account)) {
    //       accountObj[this.$t('portalSessions.authZTypeTableCaption')] = this.getSessionAuthZTypeTranslateForTable(
    //         this.getSessionAuthZType(account)
    //       );
    //     }
    //
    //     accountObj[this.$t('portalSessions.authZTimeBeginTableCaption')] = '—';
    //     if (this.getSessionAuthZTimeBegin(account) !== '') {
    //       accountObj[this.$t('portalSessions.authZTimeBeginTableCaption')] = this.getSessionAuthZTimeBegin(account);
    //     }
    //
    //     accountObj[this.$t('portalSessions.authZTimeEndTableCaption')] = '—';
    //     if (this.getSessionAuthZTimeEnd(account) !== '') {
    //       accountObj[this.$t('portalSessions.authZTimeEndTableCaption')] = this.getSessionAuthZTimeEnd(account);
    //     }
    //
    //     accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
    //       'portalSessions.isDeletedForDownloadsNo'
    //     );
    //     if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
    //       accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
    //         'portalSessions.isDeletedForDownloadsYes'
    //       );
    //     }
    //     result.push(accountObj);
    //   });
    //   return result;
    // },


    formatPortalSessionsForSocialNetworksView() {
      const result = [];
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.userAccountsList) {
        const accountObj = {};
        accountObj[this.$t('portalSessions.identityTableCaption')] = '—';
        if (this.getSessionIdentity(account)) {
          accountObj[this.$t('portalSessions.identityTableCaption')] = this.getSessionIdentity(account);
        }
        accountObj[this.$t('portalSessions.creationSessionForTable')] = '—';
        if (account.create) {
          accountObj[this.$t('portalSessions.creationSessionForTable')] = `${moment(account.create).format(
            'll'
          )} ${moment(account.create).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.expirationSessionForTable')] = '—';
        if (account.expire_at) {
          accountObj[this.$t('portalSessions.expirationSessionForTable')] = `${moment(account.expire_at * 1000).format(
            'll'
          )} ${moment(account.expire_at * 1000).format('LTS')}`;
        }
        accountObj[this.$t('portalSessions.stateTableCaption')] = '—';
        if (this.getSessionStateTypeTranslateForTable(account.state)) {
          accountObj[this.$t('portalSessions.stateTableCaption')] = this.getSessionStateTypeTranslateForTable(
            account.state
          );
        }

        accountObj[this.$t('portalSessions.authZStateTableCaption')] = '—';
        if (this.getSessionAuthZState(account)) {
          accountObj[this.$t('portalSessions.authZStateTableCaption')] = this.getSessionAuthZStateTypeTranslateForTable(
            this.getSessionAuthZState(account)
          );
        }

        accountObj[this.$t('portalSessions.authZTypeTableCaption')] = '—';
        if (this.getSessionAuthZType(account)) {
          accountObj[this.$t('portalSessions.authZTypeTableCaption')] = this.getSessionAuthZTypeTranslateForTable(
            this.getSessionAuthZType(account)
          );
        }

        accountObj[this.$t('portalSessions.socialNetworksNameTableCaption')] = '—';
        if (
          this.getSessionAuthZType(account) === 'vk' ||
          this.getSessionAuthZType(account) === 'facebook' ||
          this.getSessionAuthZType(account) === 'account'
        ) {
          accountObj[
            this.$t('portalSessions.socialNetworksNameTableCaption')
          ] = `${this.getSessionSocialNetworkObjectField(
            account,
            this.getSessionAuthZType(account),
            'first_name'
          )} ${this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'last_name')}`;
        }

        accountObj[this.$t('portalSessions.socialNetworksIdTableCaption')] = '—';
        if (
          this.getSessionAuthZType(account) === 'vk' ||
          this.getSessionAuthZType(account) === 'facebook' ||
          this.getSessionAuthZType(account) === 'account'
        ) {
          accountObj[this.$t('portalSessions.socialNetworksIdTableCaption')] = this.getSessionSocialNetworkObjectField(
            account,
            this.getSessionAuthZType(account),
            'id'
          );
        }

        accountObj[this.$t('portalSessions.socialNetworksIdTableCity')] = '—';
        if (
          (this.getSessionAuthZType(account) === 'vk' ||
            this.getSessionAuthZType(account) === 'facebook' ||
            this.getSessionAuthZType(account) === 'account') &&
          this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'city') !== ''
        ) {
          accountObj[this.$t('portalSessions.socialNetworksIdTableCity')] = this.getSessionSocialNetworkObjectField(
            account,
            this.getSessionAuthZType(account),
            'city'
          );
        }
        accountObj[this.$t('portalSessions.socialNetworksIdTableBirthday')] = '—';
        if (
          (this.getSessionAuthZType(account) === 'vk' ||
            this.getSessionAuthZType(account) === 'facebook' ||
            this.getSessionAuthZType(account) === 'account') &&
          this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'birthday') !== ''
        ) {
          accountObj[this.$t('portalSessions.socialNetworksIdTableBirthday')] = this.getSessionSocialNetworkObjectField(
            account,
            this.getSessionAuthZType(account),
            'birthday'
          );
        }

        accountObj[this.$t('portalSessions.socialNetworksIdTableEmail')] = '—';
        if (
          (this.getSessionAuthZType(account) === 'vk' ||
            this.getSessionAuthZType(account) === 'facebook' ||
            this.getSessionAuthZType(account) === 'account') &&
          this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'email') !== ''
        ) {
          accountObj[this.$t('portalSessions.socialNetworksIdTableEmail')] = this.getSessionSocialNetworkObjectField(
            account,
            this.getSessionAuthZType(account),
            'email'
          );
        }

        accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
          'portalSessions.isDeletedForDownloadsNo'
        );
        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          accountObj[this.$t('portalSessions.isDeletedForDownloadsForTableCaption')] = this.$t(
            'portalSessions.isDeletedForDownloadsYes'
          );
        }
        result.push(accountObj);
      });
      return result;
    },
    formatPortalSessionsToExcel() {
      if (this.tableViewType === 'standardView') {
        return this.formatPortalSessionsToExcelForStandardView();
      } if (this.tableViewType === 'authenticationView') {
        return this.formatPortalSessionsToExcelForAuthenticationView();
      } if (this.tableViewType === 'authorizationView') {
        return this.formatPortalSessionsToExcelForAuthorizationView();
      } if (this.tableViewType === 'socialNetworksView') {
        return this.formatPortalSessionsToExcelForSocialNetworksView();
      }
      // if (this.tableViewType === 'subscriptionsView') {
      //   return this.formatPortalSessionsToExcelForSubscriptionsView();
      // }
      return this.formatPortalSessionsToExcelForStandardView();
    },
    formatPortalSessionsToExcelForStandardView() {
      const result = {};
      if (this.portalSessionsList.length === 0) {
        return result;
      }
      result[`${this.$t('portalSessions.title')}`] = [];
      const accountsToExcel = result[`${this.$t('portalSessions.title')}`];
      accountsToExcel.push([
        this.$t('portalSessions.identityTableCaption'),
        this.$t('portalSessions.stateTableCaption'),
        this.$t('general.type'),
        this.$t('portalSessions.creationSessionForTable'),
        this.$t('portalSessions.expirationSessionForTable'),
        this.$t('general.mac'),
        this.$t('portalSessions.ip'),
        this.$t('portalSessions.os'),
        this.$t('portalSessions.browser'),
        this.$t('portalSessions.profile'),
        this.$t('portalSessions.cpe'),
        this.$t('portalSessions.nas_id'),
        this.$t('portalSessions.wlan'),
        // this.$t('portalSessions.costForTable'),
        this.$t('portalSessions.isDeletedForDownloadsForTableCaption')
      ]);
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.portalSessionsList) {
        let identity = '—';
        let state = '—';
        let type = '—';
        let creationSession = '—';
        let expirationSession = '—';
        let mac = '';
        let ip = '';
        let os = '';
        let browser = '';
        let profile = '—';
        let cpe = '—';
        let nas_id = '—';
        let wlan = '—';
        //        let cost = '—';
        let isDeleted = this.$t('portalSessions.isDeletedForDownloadsNo');

        // const socialName = [];
        // const socialGender = [];
        // const socialBirthday = [];
        // const socialCity = [];
        identity = this.getSessionIdentity(account) || '—';
        state = this.getSessionStateTypeTranslateForTable(account.state);
        if (account.ua.name) {
          browser = account.ua.name;
        }
        if (account.create) {
          creationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }
        if (account.expire_at) {
          expirationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }
        if (account.ua.type) {
          type = this.$t(`portalSessions.ua.${account.ua.type}`);
        }
        if (account.ua.os) {
          os = account.ua.os;
        }
        if (account.mac) {
          mac = account.mac;
        }
        if (account.ip) {
          ip = account.ip;
        }
        if (account.wlan_id) {
          wlan = this.getWlanName(account.wlan_id);
        }
        if (account.cpe) {
          cpe = this.getCpeName(account.cpe);
        }
        if (account.nas_id) {
          nas_id = account.nas_id;
        }
        if (this.getProfileNameByID(account.profile)) {
          profile = this.getProfileNameByID(account.profile);
        }
        // if (account.hasOwnProperty('cost') && this.isSessionHasSMSAuthNType(account)) {
        //   cost = `${account.cost} ${this.$t('general.rub')}`;
        // }
        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          isDeleted = this.$t('portalSessions.isDeletedForDownloadsYes');
        }

        accountsToExcel.push([
          identity,
          state,
          type,
          creationSession,
          expirationSession,
          mac,
          ip,
          os,
          browser,
          profile,
          cpe,
          nas_id,
          wlan,
          isDeleted
        ]);
      });

      return result;
    },
    formatPortalSessionsToExcelForAuthenticationView() {
      const result = {};
      if (this.portalSessionsList.length === 0) {
        return result;
      }
      result[`${this.$t('portalSessions.title')}`] = [];
      const accountsToExcel = result[`${this.$t('portalSessions.title')}`];
      accountsToExcel.push([
        this.$t('portalSessions.identityTableCaption'),
        this.$t('portalSessions.creationSessionForTable'),
        this.$t('portalSessions.expirationSessionForTable'),
        this.$t('portalSessions.stateTableCaption'),
        this.$t('portalSessions.authenStateTableCaption'),
        this.$t('portalSessions.authenTypeTableCaption'),
        this.$t('portalSessions.costForTable'),
        this.$t('portalSessions.isDeletedForDownloadsForTableCaption')
      ]);
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.portalSessionsList) {
        let identity = '—';
        let state = '—';
        let authNstate = '—';
        let authNType = '—';
        let creationSession = '—';
        let expirationSession = '—';
        let cost = '—';
        let isDeleted = this.$t('portalSessions.isDeletedForDownloadsNo');

        identity = this.getSessionIdentity(account) || '—';
        state = this.getSessionStateTypeTranslateForTable(account.state);

        if (account.create) {
          creationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }
        if (account.expire_at) {
          expirationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }

        if (account.AuthenState) {
          authNstate = this.getSessionAuthenStateTypeTranslateForTable(account.AuthenState);
        }

        if (this.getSessionAuthenType(account)) {
          authNType = this.getSessionAuthenTypeTranslateForTable(this.getSessionAuthenType(account));
        }

        if (account.hasOwnProperty('cost') && this.isSessionHasSMSAuthNType(account)) {
          cost = `${account.cost} ${this.$t('general.rub')}`;
        }
        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          isDeleted = this.$t('portalSessions.isDeletedForDownloadsYes');
        }

        accountsToExcel.push([
          identity,
          creationSession,
          expirationSession,
          state,
          authNstate,
          authNType,
          cost,
          isDeleted
        ]);
      });

      return result;
    },
    formatPortalSessionsToExcelForAuthorizationView() {
      const result = {};
      if (this.portalSessionsList.length === 0) {
        return result;
      }
      result[`${this.$t('portalSessions.title')}`] = [];
      const accountsToExcel = result[`${this.$t('portalSessions.title')}`];
      accountsToExcel.push([
        this.$t('portalSessions.identityTableCaption'),
        this.$t('portalSessions.creationSessionForTable'),
        this.$t('portalSessions.expirationSessionForTable'),
        this.$t('portalSessions.stateTableCaption'),

        this.$t('portalSessions.authZStateTableCaption'),
        this.$t('portalSessions.authZTypeTableCaption'),
        this.$t('portalSessions.authZTimeBeginTableCaption'),
        this.$t('portalSessions.authZTimeEndTableCaption'),

        this.$t('portalSessions.isDeletedForDownloadsForTableCaption')
      ]);
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.portalSessionsList) {
        let identity = '—';
        let state = '—';
        let authZState = '—';
        let authZType = '—';
        let creationSession = '—';
        let expirationSession = '—';
        let authZTimeBegin = '—';
        let authZTimeEnd = '—';

        let isDeleted = this.$t('portalSessions.isDeletedForDownloadsNo');

        identity = this.getSessionIdentity(account) || '—';
        state = this.getSessionStateTypeTranslateForTable(account.state);

        if (account.create) {
          creationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }
        if (account.expire_at) {
          expirationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }

        if (this.getSessionAuthZState(account)) {
          authZState = this.getSessionAuthZStateTypeTranslateForTable(this.getSessionAuthZState(account));
        }

        if (this.getSessionAuthZType(account)) {
          authZType = this.getSessionAuthZTypeTranslateForTable(this.getSessionAuthZType(account));
        }

        if (this.getSessionAuthZTimeBegin(account) !== '') {
          authZTimeBegin = this.getSessionAuthZTimeBegin(account);
        }

        if (this.getSessionAuthZTimeEnd(account) !== '') {
          authZTimeEnd = this.getSessionAuthZTimeEnd(account);
        }

        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          isDeleted = this.$t('portalSessions.isDeletedForDownloadsYes');
        }

        accountsToExcel.push([
          identity,
          creationSession,
          expirationSession,
          state,
          authZState,
          authZType,
          authZTimeBegin,
          authZTimeEnd,
          isDeleted
        ]);
      });

      return result;
    },

    // formatPortalSessionsToExcelForSubscriptionsView() {
    //   const result = {};
    //   if (this.portalSessionsList.length === 0) {
    //     return result;
    //   }
    //   result[`${this.$t('portalSessions.title')}`] = [];
    //   const accountsToExcel = result[`${this.$t('portalSessions.title')}`];
    //   accountsToExcel.push([
    //     this.$t('portalSessions.identityTableCaption'),
    //     this.$t('portalSessions.creationSessionForTable'),
    //     this.$t('portalSessions.expirationSessionForTable'),
    //     this.$t('portalSessions.stateTableCaption'),
    //
    //     this.$t('portalSessions.authZStateTableCaption'),
    //     this.$t('portalSessions.authZTypeTableCaption'),
    //     this.$t('portalSessions.authZTimeBeginTableCaption'),
    //     this.$t('portalSessions.authZTimeEndTableCaption'),
    //
    //     this.$t('portalSessions.isDeletedForDownloadsForTableCaption')
    //   ]);
    //   this.portalSessionsList.forEach((account) => {
    //     // for (const account of this.portalSessionsList) {
    //     let identity = '—';
    //     let state = '—';
    //     let authZState = '—';
    //     let authZType = '—';
    //     let creationSession = '—';
    //     let expirationSession = '—';
    //     let authZTimeBegin = '—';
    //     let authZTimeEnd = '—';
    //
    //     let isDeleted = this.$t('portalSessions.isDeletedForDownloadsNo');
    //
    //     identity = this.getSessionIdentity(account) || '—';
    //     state = this.getSessionStateTypeTranslateForTable(account.state);
    //
    //     if (account.create) {
    //       creationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
    //     }
    //     if (account.expire_at) {
    //       expirationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
    //     }
    //
    //     if (this.getSessionAuthZState(account)) {
    //       authZState = this.getSessionAuthZStateTypeTranslateForTable(this.getSessionAuthZState(account));
    //     }
    //
    //     if (this.getSessionAuthZType(account)) {
    //       authZType = this.getSessionAuthZTypeTranslateForTable(this.getSessionAuthZType(account));
    //     }
    //
    //     if (this.getSessionAuthZTimeBegin(account) !== '') {
    //       authZTimeBegin = this.getSessionAuthZTimeBegin(account);
    //     }
    //
    //     if (this.getSessionAuthZTimeEnd(account) !== '') {
    //       authZTimeEnd = this.getSessionAuthZTimeEnd(account);
    //     }
    //
    //     if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
    //       isDeleted = this.$t('portalSessions.isDeletedForDownloadsYes');
    //     }
    //
    //     accountsToExcel.push([
    //       identity,
    //       creationSession,
    //       expirationSession,
    //       state,
    //       authZState,
    //       authZType,
    //       authZTimeBegin,
    //       authZTimeEnd,
    //       isDeleted
    //     ]);
    //   });
    //
    //   return result;
    // },


    formatPortalSessionsToExcelForSocialNetworksView() {
      const result = {};
      if (this.portalSessionsList.length === 0) {
        return result;
      }
      result[`${this.$t('portalSessions.title')}`] = [];
      const accountsToExcel = result[`${this.$t('portalSessions.title')}`];
      accountsToExcel.push([
        this.$t('portalSessions.identityTableCaption'),
        this.$t('portalSessions.creationSessionForTable'),
        this.$t('portalSessions.expirationSessionForTable'),
        this.$t('portalSessions.stateTableCaption'),
        this.$t('portalSessions.authZStateTableCaption'),
        this.$t('portalSessions.authZTypeTableCaption'),

        this.$t('portalSessions.socialNetworksNameTableCaption'),
        this.$t('portalSessions.socialNetworksIdTableCaption'),
        this.$t('portalSessions.socialNetworksIdTableCity'),
        this.$t('portalSessions.socialNetworksIdTableBirthday'),
        this.$t('portalSessions.socialNetworksIdTableEmail'),

        this.$t('portalSessions.isDeletedForDownloadsForTableCaption')
      ]);
      this.portalSessionsList.forEach((account) => {
        // for (const account of this.portalSessionsList) {
        let identity = '—';
        let state = '—';
        let authZState = '—';
        let authZType = '—';
        let creationSession = '—';
        let expirationSession = '—';
        let name = '—';
        let city = '—';
        let birthday = '—';
        let email = '—';
        let id = '—';

        let isDeleted = this.$t('portalSessions.isDeletedForDownloadsNo');

        identity = this.getSessionIdentity(account) || '—';
        state = this.getSessionStateTypeTranslateForTable(account.state);

        if (account.create) {
          creationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }
        if (account.expire_at) {
          expirationSession = `${moment(account.create).format('ll')} ${moment(account.create).format('LTS')}`;
        }

        if (this.getSessionAuthZState(account)) {
          authZState = this.getSessionAuthZStateTypeTranslateForTable(this.getSessionAuthZState(account));
        }

        if (this.getSessionAuthZType(account)) {
          authZType = this.getSessionAuthZTypeTranslateForTable(this.getSessionAuthZType(account));
        }

        if (
          this.getSessionAuthZType(account) === 'vk' ||
          this.getSessionAuthZType(account) === 'facebook' ||
          this.getSessionAuthZType(account) === 'account'
        ) {
          name = `${this.getSessionSocialNetworkObjectField(
            account,
            this.getSessionAuthZType(account),
            'first_name'
          )} ${this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'last_name')}`;
        }

        if (
          this.getSessionAuthZType(account) === 'vk' ||
          this.getSessionAuthZType(account) === 'facebook' ||
          this.getSessionAuthZType(account) === 'account'
        ) {
          id = this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'id');
        }

        if (
          (this.getSessionAuthZType(account) === 'vk' ||
            this.getSessionAuthZType(account) === 'facebook' ||
            this.getSessionAuthZType(account) === 'account') &&
          this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'city') !== ''
        ) {
          city = this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'city');
        }

        if (
          (this.getSessionAuthZType(account) === 'vk' ||
            this.getSessionAuthZType(account) === 'facebook' ||
            this.getSessionAuthZType(account) === 'account') &&
          this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'birthday') !== ''
        ) {
          birthday = this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'birthday');
        }

        if (
          (this.getSessionAuthZType(account) === 'vk' ||
            this.getSessionAuthZType(account) === 'facebook' ||
            this.getSessionAuthZType(account) === 'account') &&
          this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'email') !== ''
        ) {
          email = this.getSessionSocialNetworkObjectField(account, this.getSessionAuthZType(account), 'email');
        }

        if (account.hasOwnProperty('is_deleted') && this.isSessionDeleted(account)) {
          isDeleted = this.$t('portalSessions.isDeletedForDownloadsYes');
        }

        accountsToExcel.push([
          identity,
          creationSession,
          expirationSession,
          state,
          authZState,
          authZType,
          name,
          id,
          city,
          birthday,
          email,
          isDeleted
        ]);
      });

      return result;
    },
    isSessionDeleted(sessionObj) {
      if (sessionObj.hasOwnProperty('is_deleted') && sessionObj.is_deleted === true) {
        return true;
      }
      return false;
    },
    deleteSession(sessionObj) {
      const sessionForDelete = JSON.parse(JSON.stringify(sessionObj));
      sessionForDelete.is_deleted = true;
      portalSessionsService.updateSession(this, sessionForDelete);
    },
    deleteSessionAndCloseDeleteSessionModalWindow(session) {
      this.deleteSession(session);
      this.isDeleteSessionModalOpen = false;
    },
    undoDeleteSessionAndCloseUndoDeleteSessionModalWindow(session) {
      this.undoDeleteSession(session);
      this.isUndoDeleteSessionModalOpen = false;
    },
    openDeleteSessionModalWindow(session) {
      this.isDeleteSessionModalOpen = true;
      this.updatedSession = session;
    },
    cancelClickInDeleteSessionModalWindow() {
      this.isDeleteSessionModalOpen = false;
      this.updatedSession = undefined;
    },
    undoDeleteSession(sessionObj) {
      const sessionForDelete = JSON.parse(JSON.stringify(sessionObj));
      sessionForDelete.is_deleted = false;
      portalSessionsService.updateSession(this, sessionForDelete);
    },
    openUndoDeleteSessionModalWindow(session) {
      this.isUndoDeleteSessionModalOpen = true;
      this.updatedSession = session;
    },
    cancelClickInUndoDeleteSessionModalWindow() {
      this.isUndoDeleteSessionModalOpen = false;
      this.updatedSession = undefined;
    }
  },
  watch: {
    // localeSwitchedTimeStamp(){
    //   this.reInitializei18ns();
    // }
  },
  computed: {
    ...featureFlagsMapGetters(['isContentPortalPaidInternetEnabled']),
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isCpeLoading() {
      return this.$store.state.loadingCpes;
    },
    // isFiltersActive() {
    //   return this.filters.limit !== 30 || this.filters.sort_by !== 'create_at' ||
    //     this.filters.sort_order !== 1 || this.filters.search !== '' ||
    //     (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
    //     typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 ) ||
    //     (Array.isArray(this.filters.profiles) && this.filters.profiles.length !== 0) ||
    //     this.filters.q.social_network !== '' || this.filters.q.state !== '' || this.filters.q.AuthenState !== '' || this.filters.q.authenticationType !== '' ||
    //     this.filters.q.authorizationState !== '' || this.filters.q.authorizationType !== '' || this.filters.q.is_deleted !== '' ||
    //     this.filters.q.create_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.create_at.endDate !== moment().endOf('day').valueOf() ||
    //     this.filters.q.expire_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.expire_at.endDate !== moment().endOf('day').valueOf() ||
    //     this.filters.q.start_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.start_at.endDate !== moment().endOf('day').valueOf() ||
    //     this.filters.q.stop_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.stop_at.endDate !== moment().endOf('day').valueOf() ||
    //     this.filterSettings.state !== '$eq' || this.filterSettings.AuthenState !== '$eq' || this.filterSettings.authenType !== '$eq' ||
    //     this.filterSettings.authZState !== '$eq' || this.filterSettings.authZType !== '$eq' ||
    //     this.filterSettings.enablingDateTimeFilters.create_at !== false  || this.filterSettings.enablingDateTimeFilters.expire_at !== false  ||
    //     this.filterSettings.enablingDateTimeFilters.start_at !== false  || this.filterSettings.enablingDateTimeFilters.stop_at !== false
    // },
    isFiltersActive() {
      return this.filters.limit !== 30 || this.filters.sort_by !== 'create_at' ||
        this.filters.sort_order !== -1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 ) ||
        (Array.isArray(this.filters.profiles) && this.filters.profiles.length !== 0) ||
        this.filters.q.social_network !== '' || this.filters.q.state !== '' || this.filters.q.AuthenState !== '' || this.filters.q.authenticationType !== '' ||
        this.filters.q.authorizationState !== '' || this.filters.q.authorizationType !== '' || this.filters.q.is_deleted !== '' ||
        this.filters.q.create_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.create_at.endDate !== moment().endOf('day').valueOf() ||
        this.filters.q.expire_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.expire_at.endDate !== moment().endOf('day').valueOf() ||
        this.filters.q.start_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.start_at.endDate !== moment().endOf('day').valueOf() ||
        this.filters.q.stop_at.startDate !== moment().startOf('month').valueOf() ||  this.filters.q.stop_at.endDate !== moment().endOf('day').valueOf() ||
        this.filterSettings.state !== '$eq' || this.filterSettings.AuthenState !== '$eq' || this.filterSettings.authenType !== '$eq' ||
        this.filterSettings.authZState !== '$eq' || this.filterSettings.authZType !== '$eq' ||
        this.filterSettings.enablingDateTimeFilters.create_at !== false  || this.filterSettings.enablingDateTimeFilters.expire_at !== false  ||
        this.filterSettings.enablingDateTimeFilters.start_at !== false  || this.filterSettings.enablingDateTimeFilters.stop_at !== false
    },
    isWlansLoading() {
      return this.$store.state.loadingWlans;
    },
    localeSwitchedTimeStamp() {
      return this.$store.state.localeSwitchedTimeStamp;
    },
    isDisabledWithChilds() {
      if (this.selectedLocationInPortalUserAccounts.id === '') {
        return true;
      }
      return false;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    locationsListById() {
      const result = {};
      for (const location of this.locationsList) {
        result[location.id] = location;
      }
      return result;
    },
    isPortalSessionUpdateRequestExecuting() {
      return this.$store.state.portalSessionUpdateRequestExecuting;
    },
    locationsListForFilter() {
      const result = [];
      let baseLocation = {};
      const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsList));
      // const locations = helpers.sortLocationsList(JSON.parse(JSON.stringify(this.$store.state.locationsList)));
      const locations = JSON.parse(JSON.stringify(this.$store.getters.locationsListSorted));
      // console.log(userBaseLocation)
      locations.forEach((location) => {
        if (location.id === userBaseLocation) {
          // this.filters.location = { name: location.name, id: location.id };
          baseLocation = { name: location.name, id: location.id };
          // console.log(baseLocation)
          return;
        }
        result.push({ name: location.name, id: location.id });
      });
      result.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      result.unshift(baseLocation);
      result.unshift({ name: this.$t('portalStats.allLocations') });
      // console.log(result)
      return result;
    },
    selectedProfileInMultiselectForRequest() {
      const result = [];
      if (this.selectedProfileInMultiselect.length === 0) {
        return result;
      }
      for (const profile of this.selectedProfileInMultiselect) {
        result.push(profile.id);
      }
      return result;
    },
    portalProfilesListForFilterMultiselect() {
      const list =
        this.$store.state.portalProfilesList && this.$store.state.portalProfilesList.length ?
          this.$store.state.portalProfilesList :
          [];
      const listForMultiselect = JSON.parse(JSON.stringify(list));
      if (listForMultiselect.length && listForMultiselect[0].id) {
      } else {
        listForMultiselect.shift();
      }
      listForMultiselect.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return listForMultiselect;
    },
    formattedQSocialNetwork() {
      if (this.filters.q.social_network === 'vk') {
        return { 'social_network.vk': { $exists: true } };
      }
      if (this.filters.q.social_network === 'facebook') {
        return { 'social_network.facebook': { $exists: true } };
      }
      if (this.filters.q.social_network === 'instagram') {
        return { 'social_network.instagram': { $exists: true } };
      }
      return {};
    },
    formattedQ() {
      const copy = { ...this.filters.q, ...this.formattedQSocialNetwork };

      delete copy.social_network;
      // if (!copy.home_town) {
      //   delete copy.home_town;
      // }
      // if (!copy.nas_id) {
      //   delete copy.nas_id;
      // }

      // Статус сессии фильтр
      if (copy.state === '') {
        delete copy.state;
      } else {
        const stateName = copy.state;
        if (this.filterSettings.state === '$eq') {
          copy.state = { $eq: stateName };
        } else if (this.filterSettings.state === '$ne') {
          copy.state = { $ne: stateName };
        }
      }

      // Статус идентификации фильтр
      if (copy.AuthenState === '') {
        delete copy.AuthenState;
      } else {
        const stateName = copy.AuthenState;
        delete copy.AuthenState;
        if (this.filterSettings.AuthenState === '$eq') {
          copy.authenstate = { $eq: stateName };
        } else if (this.filterSettings.AuthenState === '$ne') {
          copy.authenstate = { $ne: stateName };
        }
      }

      // // Тип идентификации  фильтр
      // if ((typeof copy.authentication !== 'object' || !Object.prototype.hasOwnProperty.call(copy.authentication, 'type') || copy.authentication.type === '')){
      //   delete copy.authentication;
      // } else {
      //   const typeName = copy.authentication.type;
      //   delete copy.authentication;
      //   if (this.filterSettings.authenType === '$eq') {
      //     copy['authentication.type'] = { $eq: typeName };
      //     if (typeName === 'sms'){
      //       copy.AuthenState = {$ne: 'need'};
      //     }
      //   } else if (this.filterSettings.authenType === '$ne') {
      //     copy['authentication.type'] = { $ne: typeName };
      //   }
      // }
      //
      // Тип идентификации  фильтр
      if (copy.authenticationType === '') {
        delete copy.authenticationType;
      } else {
        const typeName = copy.authenticationType;
        delete copy.authenticationType;
        if (this.filterSettings.authenType === '$eq') {
          copy['authentication.type'] = { $eq: typeName };
          if (typeName === 'sms') {
            copy.authenstate = { $ne: 'need' };
          }
        } else if (this.filterSettings.authenType === '$ne') {
          copy['authentication.type'] = { $ne: typeName };
        }
      }

      // // статус авторизации фильтр
      // if ((typeof copy.authorization !== 'object' || !Object.prototype.hasOwnProperty.call(copy.authorization, 'state') || copy.authorization.state === '')){
      //   delete copy.authorization;
      // } else {
      //   const state = copy.authorization.state;
      //   delete copy.authorization;
      //   if (this.filterSettings.authZState === '$eq') {
      //     copy['authorization.state'] = { $eq: state };
      //   } else if (this.filterSettings.authZState === '$ne') {
      //     copy['authorization.state'] = { $ne: state };
      //   }
      // }
      //
      // // тип авторизации фильтр
      // if ((typeof copy.authorization !== 'object' || !Object.prototype.hasOwnProperty.call(copy.authorization, 'type') || copy.authorization.type === '')){
      //   delete copy.authorization;
      // } else {
      //   const state = copy.authorization.type;
      //   delete copy.authorization;
      //   if (this.filterSettings.authZType === '$eq') {
      //     copy['authorization.type'] = { $eq: state };
      //   } else if (this.filterSettings.authZType === '$ne') {
      //     copy['authorization.type'] = { $ne: state };
      //   }
      // }

      // статус авторизации фильтр
      if (copy.authorizationState === '') {
        delete copy.authorizationState;
      } else {
        const state = copy.authorizationState;
        delete copy.authorizationState;
        if (this.filterSettings.authZState === '$eq') {
          copy['authorization.state'] = { $eq: state };
        } else if (this.filterSettings.authZState === '$ne') {
          copy['authorization.state'] = { $ne: state };
        }
      }

      // тип авторизации фильтр
      if (copy.authorizationType === '') {
        delete copy.authorizationType;
      } else {
        const state = copy.authorizationType;
        delete copy.authorizationType;
        if (this.filterSettings.authZType === '$eq') {
          copy['authorization.type'] = { $eq: state };
        } else if (this.filterSettings.authZType === '$ne') {
          copy['authorization.type'] = { $ne: state };
        }
      }

      // удалена или нет сессия
      // isDeleted
      if (copy.is_deleted === '') {
        delete copy.is_deleted;
      }

      // фильтры по дате
      // Время создания сессии (create_at)
      if (!this.filterSettings.enablingDateTimeFilters.create_at) {
        delete copy.create_at;
      } else {
        const startDate = parseInt((copy.create_at.startDate / 1000).toFixed(0));
        const endDate = parseInt((copy.create_at.endDate / 1000).toFixed(0));
        delete copy.create_at;
        copy.create_at = { $gte: startDate, $lte: endDate };
      }
      // Время истечения сессии (expire_at)
      if (!this.filterSettings.enablingDateTimeFilters.expire_at) {
        delete copy.expire_at;
      } else {
        const startDate = parseInt((copy.expire_at.startDate / 1000).toFixed(0));
        const endDate = parseInt((copy.expire_at.endDate / 1000).toFixed(0));
        delete copy.expire_at;
        copy.expire_at = { $gte: startDate, $lte: endDate };
      }
      // Начало авторизации пользователя (start_at)
      if (!this.filterSettings.enablingDateTimeFilters.start_at) {
        delete copy.start_at;
      } else {
        const startDate = parseInt((copy.start_at.startDate / 1000).toFixed(0));
        const endDate = parseInt((copy.start_at.endDate / 1000).toFixed(0));
        delete copy.start_at;
        copy.start_at = { $gte: startDate, $lte: endDate };
      }
      // Окончание авторизации пользователя (stop_at)
      if (!this.filterSettings.enablingDateTimeFilters.stop_at) {
        delete copy.stop_at;
      } else {
        const startDate = parseInt((copy.stop_at.startDate / 1000).toFixed(0));
        const endDate = parseInt((copy.stop_at.endDate / 1000).toFixed(0));
        delete copy.stop_at;
        copy.stop_at = { $gte: startDate, $lte: endDate };
      }

      // console.log(copy)
      return copy;
    },
    SOCIALS() {
      return SOCIALS;
    },
    // tablePaginationOptions() {
    //   return [
    //     { value: 'mac', label: this.$t('portalSessions.mac') },
    //     { value: 'ua.name', label: this.$t('portalSessions.browser') },
    //     { value: 'ua.os', label: this.$t('portalSessions.os') },
    //     { value: 'create_at', label: this.$t('portalSessions.create_at') },
    //     { value: 'ip', label: this.$t('portalSessions.ip') },
    //     { value: 'wlan_id', label: this.$t('portalSessions.wlan') },
    //     { value: 'cpe', label: this.$t('portalSessions.cpe') },
    //     { value: 'nas_id', label: this.$t('portalSessions.nas_id') },
    //     { value: 'profile', label: this.$t('portalSessions.profile') }
    //   ];
    // },
    tablePaginationOptions() {
      return [
        // Опции для списка Сортировка
        // вид Стандартный
        { value: 'authentication.identity', label: this.$t('portalSessions.identityTableCaption') },
        { value: 'state', label: this.$t('portalSessions.stateTableCaption') },
        { value: 'create_at', label: this.$t('portalSessions.creationSessionForTable') },
        { value: 'expire_at', label: this.$t('portalSessions.expirationSessionForTable') },
        { value: 'mac', label: this.$t('portalSessions.mac') },
        { value: 'ip', label: this.$t('portalSessions.ip') },
        { value: 'ua.os', label: this.$t('portalSessions.os') },
        { value: 'ua.name', label: this.$t('portalSessions.browser') },
        { value: 'profile', label: this.$t('portalSessions.profile') },
        { value: 'cpe', label: this.$t('portalSessions.cpe') },
        { value: 'wlan_id', label: this.$t('portalSessions.wlanTableCaption') },
        { value: 'is_deleted', label: this.$t('portalSessions.isDeletedForDownloadsForTableCaption') },
        // { value: 'nas_id', label: this.$t('portalSessions.nas_id') },
        // { value: 'nas_id', label: this.$t('portalSessions.nas_id') },
        // вид Идентификация
        { value: 'AuthenState', label: this.$t('portalSessions.authenStateTableCaption') },
        { value: 'authentication.type', label: this.$t('portalSessions.authenTypeTableCaption') },
        { value: 'cost', label: this.$t('portalSessions.costForTable') },
        // вид Авторизация
        { value: 'authorization.state', label: this.$t('portalSessions.authZStateTableCaption') },
        { value: 'authorization.type', label: this.$t('portalSessions.authZTypeTableCaption') },
        { value: 'start_at', label: this.$t('portalSessions.authZTimeBeginTableCaption') },
        { value: 'stop_at', label: this.$t('portalSessions.authZTimeEndTableCaption') },
        // вид Социальные сети
        {
          value: 'social_network.vk.first_name',
          label: `${this.$t('portalSessions.socialNetworksFirstNameTableCaption')} - VK`
        },
        {
          value: 'social_network.facebook.first_name',
          label: `${this.$t('portalSessions.socialNetworksFirstNameTableCaption')} - FB`
        },
        {
          value: 'social_network.instagram.first_name',
          label: `${this.$t('portalSessions.socialNetworksFirstNameTableCaption')} - instagram`
        },

        {
          value: 'social_network.vk.last_name',
          label: `${this.$t('portalSessions.socialNetworksSecondNameTableCaption')} - VK`
        },
        {
          value: 'social_network.facebook.last_name',
          label: `${this.$t('portalSessions.socialNetworksSecondNameTableCaption')} - FB`
        },
        {
          value: 'social_network.instagram.last_name',
          label: `${this.$t('portalSessions.socialNetworksSecondNameTableCaption')} - instagram`
        },

        { value: 'social_network.vk.id', label: `${this.$t('portalSessions.socialNetworksIdTableCaption')} - VK` },
        {
          value: 'social_network.facebook.id',
          label: `${this.$t('portalSessions.socialNetworksIdTableCaption')} - FB`
        },
        {
          value: 'social_network.instagram.id',
          label: `${this.$t('portalSessions.socialNetworksIdTableCaption')} - instagram`
        },

        { value: 'social_network.vk.city', label: `${this.$t('portalSessions.socialNetworksIdTableCity')} - VK` },
        { value: 'social_network.facebook.city', label: `${this.$t('portalSessions.socialNetworksIdTableCity')} - FB` },
        {
          value: 'social_network.instagram.city',
          label: `${this.$t('portalSessions.socialNetworksIdTableCity')} - instagram`
        },

        {
          value: 'social_network.vk.birthday',
          label: `${this.$t('portalSessions.socialNetworksIdTableBirthday')} - VK`
        },
        {
          value: 'social_network.facebook.birthday',
          label: `${this.$t('portalSessions.socialNetworksIdTableBirthday')} - FB`
        },
        {
          value: 'social_network.instagram.birthday',
          label: `${this.$t('portalSessions.socialNetworksIdTableBirthday')} - instagram`
        },

        { value: 'social_network.vk.email', label: `${this.$t('portalSessions.socialNetworksIdTableEmail')} - VK` },
        {
          value: 'social_network.facebook.email',
          label: `${this.$t('portalSessions.socialNetworksIdTableEmail')} - FB`
        },
        {
          value: 'social_network.instagram.email',
          label: `${this.$t('portalSessions.socialNetworksIdTableEmail')} - instagram`
        }
      ];
    },
    showBalanceAndTransactions() {
      return this.isContentPortalPaidInternetEnabled;
    },

    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    portalSessionsList() {
      return this.$store.state.portalSessionsList;
    },
    portalProfilesList() {
      return this.$store.state.portalProfilesList;
    },
    portalProfilesListById() {
      const result = {};
      for (const profile of this.portalProfilesList) {
        result[profile.id] = profile;
      }
      return result;
    },
    portalProfilesListForFilter() {
      const list = this.$store.state.portalProfilesList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allProfiles') });
      }
      return list;
    },
    wlansListForFilter() {
      const list = this.$store.state.wlansList || [];
      if (list.length && list[0].id) {
        list.unshift({ ssid: this.$t('portalSessions.allWlans') });
      }
      return list;
    },
    cpesListForFilter() {
      const list = this.$store.state.cpesList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalSessions.allCpes') });
      }
      return list;
    },
    isSessionsLoading() {
      return this.$store.state.loadingPortalSessions || this.$store.state.loadingPortalProfiles;
    }
  },
  beforeCreate() {
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
    // wlanService.getAllWlans(this);
    wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this);
  },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   this.$store.commit('portalProfilesListClean');
  //   this.$store.commit('portalSessionsListClean');
  //   this.clearQuery();
  //   if (this.portalSessionsList.length < 1) portalSessionsService.getSessionsWithQueryV2(this, { query: true });
  //   if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
  // },

  created() {
    this.$store.commit('setCpesList', false);
    this.$store.commit('portalProfilesListClean');
    this.$store.commit('portalSessionsListClean');
    this.clearQuery();

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }

    if (this.portalSessionsList.length < 1) portalSessionsService.getSessionsWithQueryV2(this, { query: true });
    if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
  },

  mounted() {
    this.addScrollListener();
  },
  beforeDestroy() {
    this.removeScrollListener();
  }
};
</script>

<style lang="scss">
.datepicker_height_27 {
  height: 27px !important;
  color: rgb(148, 160, 178);
}
.datepicker_width_full {
  width: 100% !important;
}
/* .loader-backdrop-portaluseraccounts:after {
  position: absolute;
  top: 30px;
} */
.text-center {
  text-align: center;
}
</style>
<style lang="scss" scoped>
.session-deleted {
  opacity: 0.9;
  color: lightgray;
}
.disbale-with-childs {
  cursor: not-allowed;
}
.custom-width-for-location-filter-left {
  width: 85% !important;
}
.custom-width-for-location-filter-right {
  width: 15% !important;
}
.location-filter-with-child-caption {
  max-height: 1em;
  min-width: 200px;
}
.custom-width-for-profile-select-in-portal-accounts {
  width: 50%;
}
.bottom-margin-for-multiselect {
  margin-bottom: 0.65rem;
}
.max-width-with-hide-in-sessions-table {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.sessions-table-cursor-default {
  cursor: default;
}
.sessions-table-cursor-pointer {
  cursor: pointer;
}

.tablesessionstransition-enter-active,
.tablesessionstransition-leave-active {
  transition: opacity 0.2s;
}

.tablesessionstransition-enter,
.tablesessionstransition-leave-to {
  opacity: 0;
}
.visability-buttons-when-not-visible {
  position: fixed;
  top: 70px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
</style>
